import React, { useCallback, useMemo, useState } from 'react'
import {
  useCreateAccessRuleMutation,
  useCreatePerkMutation,
  useDeleteAccessRuleMutation,
  useSaveAccessRuleMutation,
  useUpdatePerkMutation,
  type AccessRuleInput,
  type SeatingAreaToTables,
} from '@sevenrooms/core/api'
import {
  AccessRuleStartTypeEnum,
  PerkAccessTypeEnum,
  AccessRuleTagRestrictionEnum,
  type AccessRule,
  type AccessRules,
  type GenericTagGroup,
  type Perk,
  type PerkAssociatedAccessRulesData,
  type SeatingAreasTables,
} from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Surface, useNavigation } from '@sevenrooms/core/navigation'
import { DateOnly, Formats, TimeOnly, legacyAdapter } from '@sevenrooms/core/timepiece'
import { Button, Form, IconButton, Label, TextArea } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, DividerLine, Grid, HStack, Tooltip, VStack, Window, notify, Box, Breadcrumbs } from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, Text } from '@sevenrooms/core/ui-kit/typography'
import { cleanNullish } from '@sevenrooms/core/utils'
import { isSeatingAreaToTables } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.types'
import { getAccessRuleDefaults, getInitialState, type AccessRuleForm } from '@sevenrooms/mgr-access-rules-slideout/AccessRule.zod'
import { accessRulesMessages } from '@sevenrooms/mgr-access-rules-slideout/AccessRules.locales'
import { AccessRulesSlideout } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideout'
import { FormattedTimeBefore } from '@sevenrooms/mgr-access-rules-slideout/components/shared/FormattedTimeBefore'
import { WEEK_DAYS, toAccessRuleInput } from '@sevenrooms/mgr-access-rules-slideout/toAccessRuleInput'
import type { AccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import {
  formToPerk,
  getCreateEditPerkDefaultValues,
  getTagsGroupsToRemovePerkFrom,
  useCreateEditPerkForm,
  type CreateEditPerkFormData,
} from '@sevenrooms/mgr-perks/views/CreateEditPerk/CreateEditPerkForm.zod'
import { routes } from '@sevenrooms/routes'
import { PerkActionButtons } from '../../components/PerkActionButtons'
import { PerkAssociatedTagsForm } from '../../components/PerkAssociatedTagsForm'
import { PerkCreateAccessRuleModal } from '../../components/PerkCreateAccessRuleModal'
import { PerkNameForm } from '../../components/PerkNameForm'
import { perksMessages } from '../../locales'
import { CreateEditExclusivePerkConfirmDeleteAccessRuleModal } from './CreateEditExclusivePerkConfirmDeleteAccessRuleModal'
import type { PerkCreateAccessRuleModalFormData } from '../../components/PerkCreateAccessRuleModal/PerkCreateAccessRuleModalForm.zod'

export interface CreateEditExclusivePerkFormProps {
  perk?: Perk
  accessRulesByDay: AccessRules
  seatingAreasTables: SeatingAreasTables
  accessRulesSlideoutData: AccessRulesSlideoutData
  clientTagGroups: GenericTagGroup[]
  exclusiveAccessAudienceId: string
}

export function CreateEditExclusivePerkForm({
  perk,
  accessRulesByDay,
  seatingAreasTables,
  accessRulesSlideoutData,
  clientTagGroups,
  exclusiveAccessAudienceId: exclusiveAudienceId,
}: CreateEditExclusivePerkFormProps) {
  const { formatMessage } = useLocales()
  const perkId = perk?.id
  const { venue, venueKey } = useVenueContext()
  const nav = useNavigation()
  const { venueSettings, venueCurrencyCode } = useAppContext()

  const today = useMemo(() => new Date(), [])

  const [accessRuleToDelete, setAccessRuleToDelete] = useState<AccessRule | undefined>(undefined)

  // Access Rule being viewed in the slideout
  const [selectedAccessRule, setSelectedAccessRule] = useState<AccessRule | undefined>()

  // Functions to Create/Update/Delete Access Rules on Perk Save
  // To be removed once backend AR save updates are made
  const [createAccessRule, { isLoading: isCreatingAccessRule }] = useCreateAccessRuleMutation()
  const [saveAccessRule, { isLoading: isSavingAccessRule }] = useSaveAccessRuleMutation()
  const [deleteAccessRule, { isLoading: isDeletingAccessRule }] = useDeleteAccessRuleMutation()

  // Functions to Create/Update Perk
  const [createPerk, { isLoading: isCreatingPerk }] = useCreatePerkMutation()
  const [updatePerk, { isLoading: isUpdatingPerk }] = useUpdatePerkMutation()

  const isMutating = isCreatingAccessRule || isSavingAccessRule || isDeletingAccessRule || isCreatingPerk || isUpdatingPerk

  // Storage for Create/Update/Delete operations to execute on Perk Save
  const [accessRuleCreatesById, setAccessRuleCreatesById] = useState<{ [key: string]: AccessRuleInput }>({})
  const [accessRuleUpdatesById, setAccessRuleUpdatesById] = useState<{ [key: string]: AccessRuleInput }>({})
  const [accessRulesToDelete, setAccessRulesToDelete] = useState<AccessRule[]>([])

  // Counter for temporary Ids given to not yet created Access Rules
  const [temporaryId, setTemporaryId] = useState<number>(0)

  /**
   * seatingAreasTablesIdToDisplayMap - Display mapping of seating/table ids to display names for the AR table
   * seatingAreaCodesToTables - Seating Areas data for the Create EI AR modal
   */
  const { seatingAreasTablesIdToDisplayMap, seatingAreaCodesToTables } = useMemo(() => {
    const seatingAreasTablesIdToDisplayMap: { [key: string]: string } = {}
    const seatingAreaCodesToTables = seatingAreasTables.seatingAreaCodesToTables.map((areaCode: SeatingAreaToTables) => {
      seatingAreasTablesIdToDisplayMap[areaCode.id] = areaCode.name
      return {
        section: false,
        id: areaCode.id,
        value: { isTable: false },
        label: areaCode.name,
        children: areaCode.tables.map(table => {
          seatingAreasTablesIdToDisplayMap[table.id] = table.itemCode
          return {
            id: table.id,
            value: { isTable: true },
            label: table.itemCode,
          }
        }),
      }
    })
    return { seatingAreasTablesIdToDisplayMap, seatingAreaCodesToTables }
  }, [seatingAreasTables.seatingAreaCodesToTables])

  /**
   * accessRulesOptions - AR options for the Create EI AR modal
   * accessRulesById - Mapping of AR IDs to the actual ARs
   * existingAssociatedAccessRules - List of existing ARs associated with the EI Perk
   */
  const { accessRulesOptions, accessRulesById, existingAssociatedAccessRulesById } = useMemo(() => {
    const accessRulesOptions: { id: string; label: string; accessRule: AccessRule }[] = []
    const persistentIds = new Set()
    const existingAssociatedAccessRulesById: { [key: string]: AccessRule } = {}
    Object.values(accessRulesByDay ?? {}).forEach(accessRules => {
      accessRules.forEach(accessRule => {
        if (!persistentIds.has(accessRule.persistentId)) {
          persistentIds.add(accessRule.persistentId)
          if (perk && perk.associatedAccessRulesData[accessRule.persistentId]) {
            existingAssociatedAccessRulesById[accessRule.id] = accessRule
          } else if (!(accessRule.isOverride || accessRule.exclusiveAccessPerkId)) {
            accessRulesOptions.push({
              id: accessRule.persistentId,
              label: accessRule.name,
              accessRule,
            })
          }
        }
      })
    })

    const accessRulesById: { [key: string]: AccessRule } = accessRulesOptions.reduce(
      (acc, option) => ({
        ...acc,
        [option.id]: option.accessRule,
      }),
      {}
    )

    return { accessRulesOptions, accessRulesById, existingAssociatedAccessRulesById }
  }, [accessRulesByDay, perk])

  // Track both created and not yet created associated access rules
  const [associatedAccessRulesById, setAssociatedAccessRulesById] =
    useState<{ [key: string]: AccessRule }>(existingAssociatedAccessRulesById)

  /**
   * For the "Create Access Rule From Scratch" option
   *
   * I couldn't find an existing way to generate a defaulted AccessRule object on the frontend.
   * As a workaround I'm instead generating the initial AccessRuleForm, converting that to an
   * AccessRuleInput, and then converting that to an AccessRule.
   */
  const seatingAreaToTables = useMemo(
    () => accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables.filter(isSeatingAreaToTables) ?? [],
    [accessRulesSlideoutData.seatingAreaData.newSeatingAreaCodesToTables]
  )
  const tagGroups = useMemo(
    () => new Map(accessRulesSlideoutData.reservationTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.reservationTagGroups]
  )
  const clientTagGroupsMap = useMemo(
    () => new Map(accessRulesSlideoutData.clientTagGroups.map(tagGroup => [tagGroup.id, tagGroup])),
    [accessRulesSlideoutData.clientTagGroups]
  )
  const getDefaultAccessRule = () => {
    const defaultAccessRuleFormData: AccessRuleForm = getInitialState({
      accessRule: undefined,
      allShifts: accessRulesSlideoutData.allShifts,
      audienceHierarchy: accessRulesSlideoutData.audienceHierarchy,
      currencyCode: venueCurrencyCode,
      editPhoto: formatMessage(accessRulesMessages.editPhoto),
      name: formatMessage(perksMessages.exclusiveAccess),
      startDate: today,
      shiftCategories: [],
      policies: accessRulesSlideoutData.policies,
      seatingAreaToTables,
      startOfDayTime: venueSettings.startOfDayTime,
      tagGroups,
      clientTagGroups: clientTagGroupsMap,
      upsells: accessRulesSlideoutData.upsells,
      accessRuleDefaults: getAccessRuleDefaults({
        defaultBookingPolicyId: accessRulesSlideoutData.defaultPolicies.bookingPolicyId ?? 'default',
        defaultCancelPolicyId: accessRulesSlideoutData.defaultPolicies.cancelPolicyId ?? 'default',
        policies: accessRulesSlideoutData.policies,
        clientTagGroups: clientTagGroupsMap,
      }),
    })
    const prepData = toAccessRuleInput(
      today.toISOString(),
      venue.id,
      venueSettings.isGoogleBookingEnabled,
      venueSettings.isTheforkIntegrationEnabled,
      'all'
    )
    const defaultAccessRuleInput: AccessRuleInput = prepData(defaultAccessRuleFormData)
    return accessRuleInputToAccessRule({ id: '', accessRuleInput: defaultAccessRuleInput })
  }

  // Initialize Perk form
  const defaultValues = useMemo(() => getCreateEditPerkDefaultValues({ perk, conciergeAccess: false }), [perk])
  const form = useForm(useCreateEditPerkForm(), {
    defaultValues,
  })
  const { field, watch } = form

  const perkInternalName = watch('internalName')

  /**
   * Takes the output of the PerkCreateAccessRuleModalForm and generates a new Access Rule object
   * that is either completely new or a clone of an existing rule but with the Seating Area and Booking
   * Window set as per the values selected in the PerkCreateAccessRuleModal.
   */
  const handlePerkCreateAccessRuleModalOnSubmit = (perkAccessRuleModalFormData: PerkCreateAccessRuleModalFormData) => {
    const { seatingAreasTables, enableReleaseTables, releaseTableTimeData, cloneExistingAccessRule, accessRuleToCloneId } =
      perkAccessRuleModalFormData
    const startDate = DateOnly.fromDate(today)

    // Seating area data
    const tableIds = seatingAreasTables.filter(({ value: { isTable } }) => isTable).map(({ id }) => id)
    const seatingAreaIds = seatingAreasTables.filter(({ value: { isTable } }) => !isTable).map(({ id }) => id)

    // Booking window data
    const cutoffNum =
      !enableReleaseTables || releaseTableTimeData.count === 0 || releaseTableTimeData.count === null
        ? undefined
        : releaseTableTimeData.count
    const cutoffType = enableReleaseTables && cutoffNum ? releaseTableTimeData.unit : undefined
    const cutoffHour =
      enableReleaseTables && cutoffNum && releaseTableTimeData.beforeTime && releaseTableTimeData.beforeTime !== '0'
        ? releaseTableTimeData.beforeTime
        : undefined

    const existingAccessRule = accessRulesById[accessRuleToCloneId]

    /**
     * Whether we're cloning an existing rule or creating a new one from scratch,
     * override the existing values for these seating area and booking window
     * fields with the values selected in the modal. Also, override the existing
     * rule's publicTimeSlotDescription, publicDescriptionTitle, timseslotDescription,
     * isHeld, and audienceTiers parameters
     */
    const accessRuleParamOverrides: Partial<AccessRule> = {
      tableIDS: tableIds,
      seatingAreaIDS: seatingAreaIds,
      cutoffNum,
      cutoffType,
      cutoffHour,
      startDate,
      id: '',
      isHeld: true,
      audienceTiers: [
        {
          audienceTierId: undefined,
          channels: [],
          startType: AccessRuleStartTypeEnum.DAYS,
          concierges: [],
          thirdParties: [exclusiveAudienceId],
          startNum: 90,
          tagRestriction: AccessRuleTagRestrictionEnum.NONE,
          clientTags: [],
        },
      ],
      publicTimeSlotDescription: formatMessage(perksMessages.exclusiveAccess),
    }

    if (cloneExistingAccessRule && existingAccessRule) {
      const isExistingExclusive = perk?.associatedAccessRulesData[existingAccessRule.persistentId]
      const newAccessRule = {
        ...existingAccessRule,
        name: perk?.associatedAccessRulesData[existingAccessRule.persistentId]
          ? formatMessage(perksMessages.copyOf, { name: existingAccessRule.name })
          : formatMessage(perksMessages.exclusiveAccessSuffix, { name: existingAccessRule.name }),
        ...accessRuleParamOverrides,
      }
      if (existingAccessRule.publicTimeSlotDescription) {
        newAccessRule.publicTimeSlotDescription = isExistingExclusive
          ? formatMessage(perksMessages.copyOf, { name: existingAccessRule.publicTimeSlotDescription })
          : formatMessage(perksMessages.exclusiveAccessSuffix, { name: existingAccessRule.publicTimeSlotDescription })
      }
      if (existingAccessRule.publicDescriptionTitle) {
        newAccessRule.publicDescriptionTitle = isExistingExclusive
          ? formatMessage(perksMessages.copyOf, { name: existingAccessRule.publicDescriptionTitle })
          : formatMessage(perksMessages.exclusiveAccessSuffix, { name: existingAccessRule.publicDescriptionTitle })
      }
      setSelectedAccessRule(newAccessRule)
    } else {
      const newAccessRule = {
        ...getDefaultAccessRule(),
        ...accessRuleParamOverrides,
      }
      setSelectedAccessRule(newAccessRule)
    }
  }

  /**
   * Takes the output of the form, which is an AccessRuleInput,
   * and generates an actual AccessRule from it. Given that it hasn't actually been created yet, we assign it a
   * temporary id for tracking purposes.
   *
   * The AccessRuleInput is saved to state so that when we save the perk, we can submit the AccessRuleInputs
   * for the necessary create/update operations. The generated AccessRule is added to the list of associated
   * Access Rules to be displayed in the table
   */
  const onRuleSaveOverride = ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }): void => {
    // Manually setting isRecurring and entireSeries to true so we apply changes to the rule on all days.
    const updatedAccessRuleInput: AccessRuleInput = { ...accessRuleInput, isRecurring: true, entireSeries: true }
    const accessRule = accessRuleInputToAccessRule({ accessRuleInput: updatedAccessRuleInput, id })
    setAssociatedAccessRulesById({ ...associatedAccessRulesById, [accessRule.id]: accessRule })
    if (id === '' || parseInt(accessRule.id)) {
      // If the rule has an integer id, it's a temporary id, so it hasn't been created yet
      setAccessRuleCreatesById({ ...accessRuleCreatesById, [accessRule.id]: updatedAccessRuleInput })
    } else {
      // Else it's and existing rule that needs to be updated
      setAccessRuleUpdatesById({ ...accessRuleUpdatesById, [accessRule.id]: updatedAccessRuleInput })
    }
    setSelectedAccessRule(undefined)
  }

  const onAccessRuleDelete = (accessRule: AccessRule) => {
    const { id } = accessRule

    // If it was an existing access rule, we'll need to delete it from the db
    if (existingAssociatedAccessRulesById[id]) {
      setAccessRulesToDelete([...accessRulesToDelete, accessRule])
    }

    // Remove any planned create/update operations
    const { [id]: _removeCreate, ...updatedAccessRulesToCreateById } = accessRuleCreatesById
    setAccessRuleCreatesById(updatedAccessRulesToCreateById)
    const { [id]: _removeUpdate, ...updatedAccessRulesToUpdateById } = accessRuleUpdatesById
    setAccessRuleUpdatesById(updatedAccessRulesToUpdateById)

    // Remove it from the list of associated access rules
    const { [id]: _removeAssociatedAccessRule, ...updatedAssociatedAccessRulesById } = associatedAccessRulesById
    setAssociatedAccessRulesById(updatedAssociatedAccessRulesById)
  }

  /**
   * Converts an AccessRuleInput to an AccessRule. AccessRuleInputs don't have ids, so id is passed in as an argument to determine
   * if we should use an existing id or assign a temporary one.
   */
  const accessRuleInputToAccessRule = useCallback(
    ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }): AccessRule => {
      let accessRuleId = id
      if (id === '') {
        accessRuleId = `${temporaryId}`
        setTemporaryId(temporaryId + 1)
      }

      return {
        ...cleanNullish(accessRuleInput),
        audienceTiers: accessRuleInput.audienceTiers.map(audienceTier => cleanNullish(audienceTier)),
        dayOfWeek: WEEK_DAYS.map(weekDay => accessRuleInput.days.includes(weekDay)),
        endDate: accessRuleInput.endDate ? DateOnly.fromDate(new Date(accessRuleInput.endDate)) : undefined,
        endTime: accessRuleInput.endTime
          ? TimeOnly.fromJsDate(new Date(0, 0, 0, ...accessRuleInput.endTime.split(':').map(timePart => parseInt(timePart))))
          : undefined,
        endTimeString: accessRuleInput.endTime ?? undefined, // endTimeString takes precedence over endTimeDisplay
        excludedDates: [], // Not used in AccessRule.zod.ts
        id: accessRuleId,
        ignoreDescriptionsFor3PBookers: accessRuleInput.allowUnsupportedChannels,
        isOverride: false, // Rules made from Perks will never be Overrides
        paymentPolicyId: accessRuleInput.cancellationPolicyId ?? undefined,
        persistentId: '', // Persistent ID is immaterial here
        policy: accessRuleInput.bookingPolicy ?? undefined,
        publicDescriptionTitle: accessRuleInput.publicTitle ?? undefined,
        publicLongFormDescription: accessRuleInput.publicDescription ?? undefined,
        publicPhoto: accessRuleInput.publicPhotoUrl ?? undefined,
        publicTimeSlotDescription: accessRuleInput.timeslotDescription ?? undefined,
        restrictToShifts: accessRuleInput.restrictToShifts ?? false,
        seatingAreaIDS: accessRuleInput.seatingAreaIds,
        selectedAutomaticUpsells: accessRuleInput.bundledUpgrades,
        startDate: DateOnly.fromDate(new Date(accessRuleInput.startDate)),
        startEndTimesByShiftDisplay: [], // Not used in AccessRule.zod.ts
        startTime: accessRuleInput.startTime
          ? TimeOnly.fromJsDate(new Date(0, 0, 0, ...accessRuleInput.startTime.split(':').map(timePart => parseInt(timePart))))
          : undefined,
        startTimeString: accessRuleInput.startTime ?? undefined, // startTimeString takes precedence over startTimeDisplay
        tableIDS: accessRuleInput.tableIds,
      }
    },
    [temporaryId]
  )

  const deleteAccessRuleAsync = useCallback(
    async ({ accessRule, date }: { accessRule: AccessRule; date: string }) => {
      const { name } = accessRule
      try {
        await deleteAccessRule({
          from_date: date,
          entire_series: true,
          is_recurring: true,
          id: accessRule.id,
          venueId: venue.id,
        }).unwrap()
      } catch {
        return formatMessage(accessRulesMessages.removeFailed, { name })
      }
      return undefined
    },
    [deleteAccessRule, venue.id, formatMessage]
  )

  const updateAccessRuleAsync = useCallback(
    async ({ id, accessRuleInput }: { id: string; accessRuleInput: AccessRuleInput }) => {
      const { name } = accessRuleInput
      try {
        await saveAccessRule({
          args: { id, venueId: venue.id },
          data: accessRuleInput,
        }).unwrap()
      } catch {
        return formatMessage(accessRulesMessages.saveFailed, { name })
      }
      return undefined
    },
    [saveAccessRule, venue.id, formatMessage]
  )

  const createAccessRuleAsync = useCallback(
    async ({ accessRuleInput }: { accessRuleInput: AccessRuleInput }) => {
      const { name } = accessRuleInput
      try {
        const response = await createAccessRule({
          args: { venueId: venue.id },
          data: accessRuleInput,
        }).unwrap()
        return {
          persistentId: response.persistentId,
          error: undefined,
        }
      } catch {
        return {
          persistentId: undefined,
          error: formatMessage(accessRulesMessages.saveFailed, { name }),
        }
      }
    },
    [createAccessRule, venue.id, formatMessage]
  )

  /**
   * Ideally, this function would send the Perk to the server with an attached list of AccessRuleInputs to create,
   * a dict of AccessRuleInputs by ID to update, and a list of AccessRule Persistent IDs to delete.
   *
   * The server would delete the Access Rules by persistent_id and remove them from the Perk's associated_access_rules_data
   * list. Then we'd iterate through the update and create operations and update the associated_access_rules_data
   * with the IDs of the newly created access rules. This save functionality should have been part of MA-1616
   *
   * However, the code in booking_access.py for parsing AccessRuleInput currently appears to be tied to extracting params
   * for a single update from a Request object. As properly decoupling will take some time, for now I'm just making individual
   * requests to the relevant create/update/delete endpoints
   */
  const handleOnSubmit = useCallback(
    async (createEditPerkFormData: CreateEditPerkFormData) => {
      const formattedDateTime = legacyAdapter({ format: Formats.iso, value: new Date().toISOString() }, Formats.MonthDayYearByDash)
      const errors = []
      const associatedAccessRulesData = perk ? { ...perk.associatedAccessRulesData } : {}

      // 1) Delete Access Rules
      for (const accessRule of accessRulesToDelete) {
        const deleteError = await deleteAccessRuleAsync({ accessRule, date: formattedDateTime })
        if (deleteError) {
          errors.push(deleteError)
        } else {
          // If the delete succeeded, remove the persistent id from the Perk's associated access rules data
          delete associatedAccessRulesData[accessRule.persistentId]
        }
      }

      // 2) Update Access Rules
      for (const [id, accessRuleUpdate] of Object.entries(accessRuleUpdatesById)) {
        const updateError = await updateAccessRuleAsync({ id, accessRuleInput: accessRuleUpdate })
        if (updateError) {
          errors.push(updateError)
        }
      }

      // 3) Create Access Rules and save new persistent IDs
      const newAssociatedAccessRulesData: PerkAssociatedAccessRulesData = {}
      for (const accessRuleCreate of Object.values(accessRuleCreatesById)) {
        const { persistentId, error: createError } = await createAccessRuleAsync({
          accessRuleInput: accessRuleCreate,
        })
        if (createError) {
          errors.push(createError)
        }
        if (persistentId) {
          newAssociatedAccessRulesData[persistentId] = { audienceTierIds: [] }
        }
      }

      // 4) Update associated access rules persistent IDs and save Perk
      const updatedPerk = formToPerk({
        createEditPerkFormData,
        associatedAccessRulesData: { ...associatedAccessRulesData, ...newAssociatedAccessRulesData },
      })
      updatedPerk.perkType = PerkAccessTypeEnum.EXCLUSIVE_ACCESS

      const createUpdatePromise =
        perk && perkId
          ? updatePerk({
              venueId: venue.id,
              perkId,
              perk: updatedPerk,
              tagGroupsToRemovePerkFrom: getTagsGroupsToRemovePerkFrom(perk, createEditPerkFormData),
            })
          : createPerk({ venueId: venue.id, perk: updatedPerk })

      try {
        await createUpdatePromise
      } catch (e) {
        errors.push(formatMessage(perksMessages.perkCreateUpdateError))
      }

      if (errors.length > 0) {
        errors.push(formatMessage(perksMessages.perkCreateUpdateExclusiveErrorTemporary))
        notify({
          content: errors.join(' '),
          type: 'error',
        })
      } else {
        notify({
          content: formatMessage(perk && perkId ? perksMessages.perkUpdateSuccess : perksMessages.perkCreateSuccess, {
            perkInternalName: perkInternalName || 'Perk',
          }),
          type: 'success',
        })
        nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
      }
    },
    [
      accessRuleCreatesById,
      accessRuleUpdatesById,
      accessRulesToDelete,
      createAccessRuleAsync,
      createPerk,
      deleteAccessRuleAsync,
      formatMessage,
      nav,
      perk,
      perkId,
      updateAccessRuleAsync,
      updatePerk,
      venue.id,
      venue.urlKey,
      perkInternalName,
    ]
  )

  const handleOnCancel = useCallback(() => {
    nav.push(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })
  }, [nav, venue])

  const getPageTitle = () =>
    perkId
      ? formatMessage(perksMessages.editPerk, { perkName: perk?.internalName ?? 'Perk' })
      : formatMessage(perksMessages.createNewExclusivePerk)

  const getSeatingAreasTablesDisplay = (accessRule: AccessRule): string => {
    const seatingAreaDisplays = accessRule.seatingAreaIDS.map(seatingAreaId => seatingAreasTablesIdToDisplayMap[seatingAreaId])
    const tableDisplays = accessRule.tableIDS.map(tableId => seatingAreasTablesIdToDisplayMap[tableId])
    return [...seatingAreaDisplays, ...tableDisplays].join(', ')
  }

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={getPageTitle()} />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={() => {}}>
        <SettingsPageContent
          headerWidth="calc(100% - 274px)"
          title={getPageTitle()}
          actions={<PerkActionButtons disabled={isMutating || !perkInternalName} onCancel={handleOnCancel} perkId={perkId} />}
          breadcrumbs={
            <Breadcrumbs>
              <Link to={nav.href(routes.manager2.marketing.perks2, { params: { venueKey: venue.urlKey } })}>
                {formatMessage(perksMessages.perksManagement)}
              </Link>
              <Text>{formatMessage(perkId ? perksMessages.editPerkBreadcrumb : perksMessages.createNewExclusivePerkBreadcrumb)}</Text>
            </Breadcrumbs>
          }
        >
          <Box pt="lm" pl="lm" pr="lm" width="100%">
            <BaseSection title={formatMessage(perksMessages.perkGeneral)}>
              <Box p="lm" width="100%">
                <VStack spacing="lm">
                  <PerkNameForm
                    disabled={isMutating}
                    internalNameField={field.prop('internalName')}
                    primaryText={formatMessage(perksMessages.internalPerksName)}
                    secondaryText={formatMessage(perksMessages.exclusivePerkNameInternalSecondary)}
                  />
                  <Box>
                    <DividerLine margin="none" />
                  </Box>
                  <VStack spacing="xs">
                    <Header>{formatMessage(perksMessages.reservationWidetLinkPrimary)}</Header>
                    <Text>{formatMessage(perksMessages.reservationWidetLinkSecondary)}</Text>
                  </VStack>
                  {perk?.associatedAccessRulesData ? (
                    <>
                      <Label
                        primary={formatMessage(perksMessages.reservationLinkContentPrimary)}
                        secondary={formatMessage(perksMessages.reservationLinkContentSecondary)}
                      >
                        {Object.keys(perk.associatedAccessRulesData).length > 0 && (
                          <HStack>
                            <Box width="50%">
                              <TextArea disabled defaultValue={perk.exclusiveAccessLink} resize="none" />
                            </Box>
                            <Tooltip
                              content={<div>{formatMessage(commonMessages.copyToClipboard)}</div>}
                              displayAction="hover"
                              alignment="top"
                            >
                              <IconButton
                                borderType="square"
                                icon="VMSWeb-copy"
                                height="100%"
                                onClick={() => {
                                  if (perk.exclusiveAccessLink) {
                                    navigator.clipboard.writeText(perk.exclusiveAccessLink)
                                  }
                                }}
                              />
                            </Tooltip>
                          </HStack>
                        )}
                      </Label>
                    </>
                  ) : (
                    <Text color="secondaryFont" fontStyle="italic">
                      {formatMessage(perksMessages.reservationLinkNotCreated)}
                    </Text>
                  )}
                  <Box>
                    <DividerLine margin="none" />
                  </Box>
                  <Box p="lm" width="100%">
                    <PerkAssociatedTagsForm
                      field={field.prop('associatedTags')}
                      clientTagGroups={clientTagGroups}
                      disabled={isMutating}
                      disableReservationTags
                    />
                  </Box>
                </VStack>
              </Box>
            </BaseSection>
          </Box>
          <Box p="lm" width="100%">
            <BaseSection hasError>
              <Box p="lm" width="100%">
                <VStack spacing="xs">
                  <Header type="h2">{formatMessage(perksMessages.accessRulesSectionTitle)}</Header>
                  <Text color="secondaryFont">{formatMessage(perksMessages.accessRulesCreateSectionDescription)}</Text>
                </VStack>
                <Grid gridTemplateColumns="repeat(3, minmax(0, 1fr))" pt="sm" pb="sm" mt="lm" gap="s">
                  <Text>{formatMessage(perksMessages.accessRulesTableAccessRuleName)}</Text>
                  <Text>{formatMessage(perksMessages.accessRulesTableReservedTablesAndSeatingAreas)}</Text>
                  <Text>{formatMessage(perksMessages.accessRulesTableReleaseInventory)}</Text>
                </Grid>
                <DividerLine margin="none" />
                {Object.values(associatedAccessRulesById).map(accessRule => (
                  <React.Fragment key={accessRule.id}>
                    <Grid gridTemplateColumns="repeat(3, minmax(0, 1fr))" cursor="pointer" alignItems="center" pt="s" pb="s" gap="s">
                      <Box pl="s" pt="xxs" pb="xxs">
                        <Text>{accessRule.name}</Text>
                      </Box>
                      <Box pt="xxs" pb="xxs">
                        <Text>{getSeatingAreasTablesDisplay(accessRule)}</Text>
                      </Box>
                      <HStack pr="s" pt="xxs" pb="xxs" alignItems="center" justifyContent="space-between">
                        {accessRule.cutoffNum ? (
                          <Text>
                            {formatMessage(commonMessages.yes)},{' '}
                            <FormattedTimeBefore
                              count={accessRule.cutoffNum}
                              unit={accessRule.cutoffType ?? null}
                              beforeTime={accessRule.cutoffHour ?? null}
                            />
                          </Text>
                        ) : (
                          <Text>{formatMessage(commonMessages.no)}</Text>
                        )}
                        <HStack>
                          <IconButton
                            size="s"
                            iconSize="sm"
                            icon="VMSWeb-edit"
                            onClick={() => setSelectedAccessRule(accessRule)}
                            borderType="none-round"
                          />
                          <IconButton
                            size="s"
                            iconSize="sm"
                            icon="VMSWeb-trash"
                            onClick={() => setAccessRuleToDelete(accessRule)}
                            borderType="none-round"
                          />
                        </HStack>
                      </HStack>
                    </Grid>
                    <DividerLine margin="none" />
                  </React.Fragment>
                ))}
                <Box pt="m" pb="m" pl="xs">
                  <Button
                    data-test="exclusive-access-create-access-rule"
                    variant="tertiary"
                    icon="VMSWeb-add"
                    onClick={() => {
                      nav.push(routes.manager2.marketing.perks2.createAccessRule, {
                        params: { venueKey },
                      })
                    }}
                    noPadding
                  >
                    {formatMessage(perksMessages.createAccessRule)}
                  </Button>
                </Box>
              </Box>
            </BaseSection>
          </Box>
        </SettingsPageContent>
      </Form>
      <Surface destination={routes.manager2.marketing.perks2.createAccessRule}>
        <Window>
          <PerkCreateAccessRuleModal
            accessRulesOptions={accessRulesOptions}
            seatingAreaCodesToTables={seatingAreaCodesToTables}
            closeHref={nav.closeSurfaceHref(routes.manager2.marketing.perks2.createAccessRule, { params: { venueKey } })}
            onSubmit={handlePerkCreateAccessRuleModalOnSubmit}
          />
        </Window>
      </Surface>
      <CreateEditExclusivePerkConfirmDeleteAccessRuleModal
        accessRuleToDelete={accessRuleToDelete}
        setAccessRuleToDelete={setAccessRuleToDelete}
        onConfirm={onAccessRuleDelete}
      />
      {selectedAccessRule && (
        <AccessRulesSlideout
          accessRule={selectedAccessRule}
          mode="new-item" // Forces slideout to open in edit mode
          onClose={() => setSelectedAccessRule(undefined)}
          onRuleSave={() => {}}
          onRuleSaveOverride={onRuleSaveOverride}
          onRuleDelete={() => {}}
          selectedDay={today.toISOString()}
          shiftCategories={[]}
          startDate={today}
          accessRulesSlideoutData={accessRulesSlideoutData}
          disabledFields={['bookingChannels', 'seatingAreas']}
        />
      )}
    </>
  )
}
