import { useCallback } from 'react'
import { useGetVenueFeeQuery, useGetVenueTaxQuery, useUpdateVenueFeesMutation } from '@sevenrooms/core/api'
import type { FeesData } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button, Form } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, DividerLine, Loader, notify } from '@sevenrooms/core/ui-kit/layout'
import { SettingsPageContent, useVenueContext } from '@sevenrooms/mgr-core'
import { FeesLocales } from '../Fees.locales'
import { FeeAPISchema } from '../schemas/Fee.zod'
import { FeeList } from './FeeList'

export function FeesManager() {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const { data: feesData, isFetching: isFetchingFees } = useGetVenueFeeQuery({ venueId })
  const { data: taxesRes, isFetching: isFetchingTaxes } = useGetVenueTaxQuery({ venueId })

  const serializedTaxes = taxesRes?.map(tax => ({
    id: tax?.id,
    label: `${tax?.name} [${tax?.value?.toString()}%]`,
  }))

  const isFetchingData = isFetchingFees || isFetchingTaxes

  const form = useForm(FeeAPISchema, { mode: 'onSubmit' })
  const { field, watch } = form
  const [updateFee] = useUpdateVenueFeesMutation()

  const onSubmit = useCallback(
    async (feeData: FeesData) => {
      await updateFee({
        venueId,
        fees: feeData?.fees,
      }).unwrap()
      notify({
        content: formatMessage(FeesLocales.feesUpdated),
        type: 'success',
      })
      window.location.reload()
    },
    [formatMessage, updateFee, venueId]
  )
  return (
    <Form {...form} onSubmit={feeData => onSubmit(feeData)} onInvalid={() => {}}>
      <SettingsPageContent
        title={<span style={{ color: '#3d8ec9' }}>{formatMessage(FeesLocales.feesTitle)}</span>}
        description={formatMessage(FeesLocales.feesDescription)}
        headerWidth="calc(100% - 274px)"
        actions={
          <Button data-test="save-fee-button" variant="primary" type="submit">
            {formatMessage(commonMessages.saveChanges)}
          </Button>
        }
      >
        <Box ml="m" pr="xl">
          <BaseSection title={formatMessage(FeesLocales.activeFees)}>
            <DividerLine ml="xxs" mb="xxs" mr="xxs" color="primarySelected" />
            <DividerLine ml="s" mt="xxs" mb="xxl" mr="s" color="primarySelected" />
            {isFetchingData ? (
              <Loader />
            ) : (
              <FeeList watch={watch} feesData={feesData} fields={field} serializedTaxes={serializedTaxes} venueId={venueId} />
            )}
          </BaseSection>
        </Box>
      </SettingsPageContent>
    </Form>
  )
}
