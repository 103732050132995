import { DaysOfTheWeekEnum, PDRTimeSlots } from '@sevenrooms/core/domain'
import { type Field, useFieldArray } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button, FormSelect, Label, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { HStack, VStack, Box } from '@sevenrooms/core/ui-kit/layout'
import { ExperiencesLocales } from '../../Experiences.locales'
import type { MinimumSpendMatrixFormData } from './PDRDetailsForm.zod'

const newItem: MinimumSpendMatrixFormData = {
  startTime: PDRTimeSlots.TWENTY_FOUR,
  endTime: PDRTimeSlots.TWENTY_THREE,
  startDay: 'MONDAY',
  endDay: 'FRIDAY',
  minimumSpend: 0,
}
export interface MinMatrixProps {
  field: Field<MinimumSpendMatrixFormData[]>
}

export function MinimumSpendMatrixComponent({ field }: MinMatrixProps) {
  const { formatMessage } = useLocales()
  const { fields, append, remove } = useFieldArray(field, '' as never)
  return (
    <VStack spacing="lm">
      {fields?.map((item, idx) => (
        <HStack key={item.id} spacing="lm">
          <VStack flex="4">
            <Label primary={formatMessage(ExperiencesLocales.pdrStartDay)} />
            <FormSelect
              searchable={false}
              field={field.prop(`${idx}.startDay`)}
              options={Object.entries(DaysOfTheWeekEnum).map(([unitId, label]) => ({
                id: unitId,
                label,
              }))}
              withEmpty
            />
          </VStack>
          <VStack flex="4">
            <Label primary={formatMessage(ExperiencesLocales.pdrStartTime)} />
            <FormSelect
              searchable={false}
              field={field.prop(`${idx}.startTime`)}
              options={Object.entries(PDRTimeSlots).map(([_key, label]) => ({
                id: label,
                label,
              }))}
              withEmpty
            />
          </VStack>
          <VStack flex="4">
            <Label primary={formatMessage(ExperiencesLocales.pdrEndDay)} />
            <FormSelect
              searchable={false}
              field={field.prop(`${idx}.endDay`)}
              options={Object.entries(DaysOfTheWeekEnum).map(([unitId, label]) => ({
                id: unitId,
                label,
              }))}
              withEmpty
            />
          </VStack>
          <VStack flex="4">
            <Label primary={formatMessage(ExperiencesLocales.pdrEndTime)} />
            <FormSelect
              searchable={false}
              field={field.prop(`${idx}.endTime`)}
              options={Object.entries(PDRTimeSlots).map(([_key, label]) => ({
                id: label,
                label,
              }))}
              withEmpty
            />
          </VStack>
          <VStack flex="4">
            <Label primary={formatMessage(ExperiencesLocales.pdrMinSpend)}> </Label>
            <FormNumberInput field={field.prop(`${idx}.minimumSpend`)} />
          </VStack>
          <VStack pt="l" mt="xs">
            <Button data-test="trash-button" onClick={() => append(item)} size="s" variant="tertiary">
              <Icon size="lg" name="VMSWeb-copy" />
            </Button>
          </VStack>
          <VStack pt="l" mt="xs">
            <Button data-test="trash-button" onClick={() => remove(idx)} size="s" variant="tertiary">
              <Icon size="lg" name="VMSWeb-trash" />
            </Button>
          </VStack>
        </HStack>
      ))}
      <Box>
        <Button data-test="add-time-slot-button" onClick={() => append(newItem)} icon="VMSWeb-add" variant="secondary" size="s">
          {formatMessage(ExperiencesLocales.pdrMinimumSpendMatrixAddSlot)}
        </Button>
      </Box>
    </VStack>
  )
}
