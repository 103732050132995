import { useLocales } from '@sevenrooms/core/locales'
import { Redirect, Route, Switch } from '@sevenrooms/core/navigation'
import { mgrAcctUpgradesMessages } from '@sevenrooms/mgr-acct-upgrades/locales'
import { AvailableUpgradePromoPage } from '@sevenrooms/mgr-acct-upgrades/views/AvailableUpgradePromoPage'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import smsMarketingPreview from '../../assets/sms-marketing-preview.png'
import { marketingUpsellMessages } from '../../locales'

export function SMSMarketingUpgrade() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const title = formatMessage(mgrAcctUpgradesMessages.textMarketing)
  const route = routes.manager2.marketing.upgrades.textMarketing.path

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={title} />
      <SettingsPageContent headerWidth="calc(100% - 274px)">
        <Switch>
          <Route path={route}>
            <AvailableUpgradePromoPage
              title={title}
              leadDescription={formatMessage(marketingUpsellMessages.smsMarketingLead)}
              numericalStats={[
                { number: 0.98, formatType: 'percent', description: formatMessage(marketingUpsellMessages.smsMarketingStat0) },
                {
                  number: 1.69,
                  formatType: 'currency',
                  currency: 'USD',
                  description: formatMessage(marketingUpsellMessages.smsMarketingStat1),
                  footnotes: [formatMessage(marketingUpsellMessages.smsMarketingStat1Footnote0)],
                },
                {
                  number: 51,
                  formatType: 'multiplier',
                  description: formatMessage(marketingUpsellMessages.smsMarketingStat2),
                  footnotes: [formatMessage(marketingUpsellMessages.smsMarketingStat2Footnote0)],
                },
              ]}
              bullets={formatMessage(marketingUpsellMessages.smsMarketingBullets, {
                li: (val: string) => `${val}\n`,
              })
                .split('\n')
                .filter((val: string) => !!val)}
              includeHeaderPaddingBottom={false}
              imageHref={smsMarketingPreview}
              imageAlt={formatMessage(marketingUpsellMessages.smsMarketingImageAlt)}
              ctaDestination="https://sevenrooms.com/text-marketing-request?Detail=&Detail-MR=&utm_source=product&utm_medium=link&utm_campaign=text_marketing_launch_2024&utm_Content=in_app"
              useNewFeatureFlag
              analyticsKeySuffix="sms-mkt"
            />
          </Route>
          <Redirect to={route} />
        </Switch>
      </SettingsPageContent>
    </>
  )
}
