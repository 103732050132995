import { useLocales } from '@sevenrooms/core/locales'
import { Redirect, Route, Switch } from '@sevenrooms/core/navigation'
import { mgrAcctUpgradesMessages } from '@sevenrooms/mgr-acct-upgrades/locales'
import { AvailableUpgradePromoPage } from '@sevenrooms/mgr-acct-upgrades/views/AvailableUpgradePromoPage'
import { SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import emailMarketingPreview from '../../assets/email-marketing-preview.gif'
import { marketingUpsellMessages } from '../../locales'

export function EmailMarketingUpgrade() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const title = formatMessage(mgrAcctUpgradesMessages.emailMarketing)
  const route = routes.manager2.marketing.upgrades.emailMarketing.path

  return (
    <>
      <SettingsPageMeta venue={venue?.name} title={title} />
      <SettingsPageContent headerWidth="calc(100% - 274px)">
        <Switch>
          <Route path={route}>
            <AvailableUpgradePromoPage
              title={title}
              leadDescription={formatMessage(marketingUpsellMessages.emailMarketingLead)}
              numericalStats={[
                { number: 2, formatType: 'multiplier', description: formatMessage(marketingUpsellMessages.emailMarketingStat0) },
                {
                  number: 48_000,
                  formatType: 'currency',
                  currency: 'USD',
                  decimalPlaces: 0,
                  description: formatMessage(marketingUpsellMessages.emailMarketingStat1),
                },
                {
                  number: 0.7,
                  formatType: 'percent',
                  description: formatMessage(marketingUpsellMessages.emailMarketingStat2),
                  footnotes: [formatMessage(marketingUpsellMessages.emailMarketingStat2Footnote0)],
                },
              ]}
              bullets={formatMessage(marketingUpsellMessages.emailMarketingBullets, {
                li: (val: string) => `${val}\n`,
              })
                .split('\n')
                .filter((val: string) => !!val)}
              imageHref={emailMarketingPreview}
              imageAlt={formatMessage(marketingUpsellMessages.emailMarketingImageAlt)}
              ctaDestination="https://sevenrooms.com/platform/email-marketing/" // TEMP: will receive updated url
              analyticsKeySuffix="email-mkt"
            />
          </Route>
          <Redirect to={route} />
        </Switch>
      </SettingsPageContent>
    </>
  )
}
