import CheckCircleOutlineRounded from '@mui/icons-material/CheckCircleOutlineRounded'
import PanoramaFishEyeRoundedIcon from '@mui/icons-material/PanoramaFishEyeRounded'
import * as React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useTheme } from '@sevenrooms/core/ui-kit'
import { messages } from '@sevenrooms/mgr-marketing-templates-gallery/locales/marketingTemplatesGallery.locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Step } from '@sevenrooms/react-components/components/Step'
import { Stepper } from '@sevenrooms/react-components/components/Stepper'
import { Typography } from '@sevenrooms/react-components/components/Typography'

export function MarketingStepperFlow() {
  const { formatMessage } = useLocales()
  const theme = useTheme()

  const steps: string[] = [
    formatMessage(messages.stepOfferDetails),
    formatMessage(messages.stepAvailability),
    formatMessage(messages.stepScheduling),
    formatMessage(messages.stepPayment),
    formatMessage(messages.stepCustomization),
  ]
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ pb: 4, borderBottom: `1px solid ${theme.colors.borders}` }}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps: { completed?: boolean } = {}
            return (
              <Step key={label} {...stepProps}>
                <Stack direction="column" alignItems="center">
                  <StepperIcon index={index} activeStep={activeStep} />
                  <Typography sx={{ pt: 3, pl: 1, fontSize: 14 }}>{label}</Typography>
                </Stack>
              </Step>
            )
          })}
        </Stepper>
      </Box>
      {activeStep === steps.length ? (
        <Typography sx={{ mt: 2, mb: 1, pl: 8 }}>{formatMessage(messages.finalPageTitle)}</Typography>
      ) : (
        <>
          <Typography sx={{ mt: 8, mb: 8, pl: 8 }}>Step {activeStep + 1}</Typography>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              pt: 2,
              borderTop: `1px solid ${theme.colors.borders}`,
              borderRadius: `${theme.borderRadius.s}`,
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Button data-test="creeate-new-offer-btn" color="inherit" variant="text" onClick={handleReset}>
                <Typography fontWeight="700">{formatMessage(messages.createNewOfferButtonText)}</Typography>
              </Button>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', pr: 2, justifyContent: 'end' }}>
              <Button data-test="handle-back-btn" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                {formatMessage(messages.backButtonText)}
              </Button>
              <Button data-test="handle-next-btn" variant="contained" onClick={handleNext}>
                {activeStep === steps.length - 1 ? formatMessage(messages.finishButtonText) : formatMessage(messages.continueButtonText)}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  )
}

interface StepperIconProps {
  index: number
  activeStep: number
}

function StepperIcon({ index, activeStep }: StepperIconProps) {
  if (index === activeStep) {
    return <PanoramaFishEyeRoundedIcon color="primary" />
  } else if (index < activeStep) {
    return <CheckCircleOutlineRounded color="primary" />
  }
  return <PanoramaFishEyeRoundedIcon color="disabled" />
}
