import { defineMessages } from '@sevenrooms/core/locales'

export const FeesLocales = defineMessages({
  feesTitle: {
    id: 'fees.title',
    defaultMessage: 'Fees Manager',
  },
  feesDescription: {
    id: 'fees.feesDescription',
    defaultMessage: 'Manage the available fees for your venue',
  },
  activeFees: {
    id: 'fees.activeFees',
    defaultMessage: '> Active Fees ',
  },
  feeAmountPercentage: {
    id: 'fees.feeAmountPercentage',
    defaultMessage: 'Amount (%)',
  },
  feeTypeDescription: {
    id: 'fees.feeTypeDescription',
    defaultMessage: 'Type',
  },
  FeeTaxType: {
    id: 'fees.feeTaxType',
    defaultMessage: 'Percentage',
  },
  FeeFixedValueType: {
    id: 'fees.FeeFixedValueType',
    defaultMessage: 'Fixed Value',
  },
  feeName: {
    id: 'fees.feeName',
    defaultMessage: 'Name',
  },
  feeAmount: {
    id: 'fees.amount',
    defaultMessage: 'Amount',
  },
  feeTaxes: {
    id: 'fees.taxes',
    defaultMessage: 'Apply Tax',
  },
  feeNameSecondary: {
    id: 'fees.feeName',
    defaultMessage: "This is how you'll refer to this fee internally and externally",
  },
  feeAddNewItem: {
    id: 'fees.feeAddNewItem',
    defaultMessage: '(+) Add Fee',
  },
  feesUpdated: {
    id: 'fees.feesUpdated',
    defaultMessage: 'Fees were updated successfully',
  },
} as const)
