import { defineMessages } from '@sevenrooms/core/locales'

export const settingsMessages = defineMessages({
  edit: {
    id: 'settings.nav.edit',
    defaultMessage: 'Edit',
  },
  generalGroup: {
    id: 'settings.nav.generalGroup',
    defaultMessage: 'General',
  },
  venueSettings: {
    id: 'settings.nav.venueSettings',
    defaultMessage: 'Venue Settings',
  },
  clientTags: {
    id: 'settings.nav.clientTags',
    defaultMessage: 'Client tags',
  },
  reservationTags: {
    id: 'settings.nav.reservationTags',
    defaultMessage: 'Reservation tags',
  },
  membershipGroups: {
    id: 'settings.nav.membershipGroups',
    defaultMessage: 'Membership groups',
  },
  fees: {
    id: 'settings.nav.fees',
    defaultMessage: 'Fees',
  },
  taxRates: {
    id: 'settings.nav.taxRates',
    defaultMessage: 'Tax Rates',
  },
  integrationsGroup: {
    id: 'settings.nav.integrationsGroup',
    defaultMessage: 'Integrations',
  },
  bookingChannels: {
    id: 'settings.nav.bookingChannels',
    defaultMessage: 'Booking Channels',
  },
  paymentGateway: {
    id: 'settings.nav.paymentGateway',
    defaultMessage: 'Payment Gateway',
  },
  paymentIntegration: {
    id: 'settings.nav.paymentIntegration',
    defaultMessage: 'Payment Processors',
  },
  customAudiences: {
    id: 'settings.nav.customAudiences',
    defaultMessage: 'Custom Audiences',
  },
  emailServiceProviders: {
    id: 'settings.nav.emailServiceProviders',
    defaultMessage: 'Email Service Providers',
  },
  reviewChannels: {
    id: 'settings.nav.reviewChannels',
    defaultMessage: 'Review Channels',
  },
  pointofsale: {
    id: 'settings.nav.pointofsale',
    defaultMessage: 'Point of Sale',
  },
  widgetGroup: {
    id: 'settings.nav.widgetGroup',
    defaultMessage: 'Widget Settings',
  },
  reservationWidget: {
    id: 'settings.nav.reservationWidget',
    defaultMessage: 'Reservation Widget',
  },
  reservationWidgetSetup: {
    id: 'settings.nav.reservationWidgetSetup',
    defaultMessage: 'Please contact support to get the Reservation Widget enabled.',
  },
  eventWidget: {
    id: 'settings.nav.eventWidget',
    defaultMessage: 'Event Widget',
  },
  eventWidgetSetup: {
    id: 'settings.nav.eventWidgetSetup',
    defaultMessage: 'Please contact support to get the Event Widget enabled.',
  },
  waitlistWidget: {
    id: 'settings.nav.waitlistWidget',
    defaultMessage: 'Waitlist Widget',
  },
  waitlistWidgetSetup: {
    id: 'settings.nav.waitlistWidgetSetup',
    defaultMessage: 'Please contact support to get the Waitlist Widget enabled.',
  },
  subscriptionWidget: {
    id: 'settings.nav.subscriptionWidget',
    defaultMessage: 'Subscription Widget',
  },
  subscriptionWidgetSetup: {
    id: 'settings.nav.subscriptionWidgetSetup',
    defaultMessage: 'Please contact support to get the Subscription Widget enabled.',
  },
  landingPage: {
    id: 'settings.nav.landingPage',
    defaultMessage: 'Landing page settings',
  },
  availabilityGroup: {
    id: 'settings.nav.availabilityGroup',
    defaultMessage: 'Availability',
  },
  daysOfOperation: {
    id: 'settings.nav.daysOfOperation',
    defaultMessage: 'Days of operation',
  },
  shifts: {
    id: 'settings.nav.shifts',
    defaultMessage: 'Shifts',
  },
  accessRules: {
    id: 'settings.nav.accessRules',
    defaultMessage: 'Access rules',
  },
  dailyProgram: {
    id: 'settings.nav.dailyProgram',
    defaultMessage: 'Daily program',
  },
  blackoutDates: {
    id: 'settings.nav.blackoutDates',
    defaultMessage: 'Blackout dates',
  },
  perks: {
    id: 'settings.nav.perks',
    defaultMessage: 'Concierge Perks',
  },
  orderingGroup: {
    id: 'settings.nav.orderingGroup',
    defaultMessage: 'Ordering',
  },
  orderingSites: {
    id: 'settings.nav.orderingSites',
    defaultMessage: 'Ordering Sites',
  },
  orderingSiteManagement: {
    id: 'settings.nav.orderingSiteManagement',
    defaultMessage: 'Ordering Site Management',
  },
  orderingEditSite: {
    id: 'settings.nav.orderingEditSite',
    defaultMessage: 'Edit Ordering Site',
  },
  menuManagement: {
    id: 'settings.nav.menuManagement',
    defaultMessage: 'Menu Management',
  },
  menuEdit: {
    id: 'settings.nav.menuEdit',
    defaultMessage: 'Edit Menu',
  },
  menuCreate: {
    id: 'settings.nav.menuCreate',
    defaultMessage: 'Create Menu',
  },
  productInventory: {
    id: 'settings.nav.productInventory',
    defaultMessage: 'Product Inventory',
  },
  productInventoryEditAll: {
    id: 'settings.nav.productInventoryEditAll',
    defaultMessage: 'Edit All',
  },
  productInventoryCreateAll: {
    id: 'settings.nav.productInventoryCreateAll',
    defaultMessage: 'Create All',
  },
  productInventoryEditMenuItem: {
    id: 'settings.nav.productInventoryEditMenuItem',
    defaultMessage: 'Edit Menu Item',
  },
  productInventoryCreateMenuItem: {
    id: 'settings.nav.productInventoryCreateMenuItem',
    defaultMessage: 'Create Menu Item',
  },
  productInventoryEditModifierGroup: {
    id: 'settings.nav.productInventoryEditModifierGroup',
    defaultMessage: 'Edit Modifier Group',
  },
  productInventoryCreateModifierGroup: {
    id: 'settings.nav.productInventoryCreateModifierGroup',
    defaultMessage: 'Create Modifier Group',
  },
  productInventoryEditModifier: {
    id: 'settings.nav.productInventoryEditModifier',
    defaultMessage: 'Edit Modifier',
  },
  productInventoryCreateModifier: {
    id: 'settings.nav.productInventoryCreateModifier',
    defaultMessage: 'Create Modifier',
  },
  eightySixProducts: {
    id: 'settings.nav.eightySixProducts',
    defaultMessage: '86 Products',
  },
  eightySixProductsTitle: {
    id: 'settings.nav.eightySixProductsTitle',
    defaultMessage: "86'd Products",
  },
  checkoutSettings: {
    id: 'settings.nav.checkoutSettings',
    defaultMessage: 'Checkout Settings',
  },
  brandSettings: {
    id: 'settings.nav.brandSettings',
    defaultMessage: 'Brand Settings',
  },
  roomNumbers: {
    id: 'settings.nav.roomNumbers',
    defaultMessage: 'Room Numbers',
  },
  guestNotifications: {
    id: 'settings.nav.guestNotifications',
    defaultMessage: 'Guest Notifications',
  },
  orderPacing: {
    id: 'settings.nav.orderPacing',
    defaultMessage: 'Order Pacing',
  },
  createRule: {
    id: 'settings.nav.createRule',
    defaultMessage: 'Create Rule',
  },
  editRule: {
    id: 'settings.nav.editRule',
    defaultMessage: 'Edit Rule',
  },
  serviceFees: {
    id: 'settings.nav.serviceFees',
    defaultMessage: 'Service Fees',
  },
  deliveryIntegrations: {
    id: 'settings.nav.deliveryIntegrations',
    defaultMessage: 'Delivery Integrations',
  },
  floorplanGroup: {
    id: 'settings.nav.floorplanGroup',
    defaultMessage: 'Floorplan',
  },
  rooms: {
    id: 'settings.nav.rooms',
    defaultMessage: 'Rooms',
  },
  seatingAreas: {
    id: 'settings.nav.seatingAreas',
    defaultMessage: 'Seating areas',
  },
  tables: {
    id: 'settings.nav.tables',
    defaultMessage: 'Tables',
  },
  tableCombinations: {
    id: 'settings.nav.tableCombinations',
    defaultMessage: 'Table combinations',
  },
  reservationStatuses: {
    id: 'settings.nav.reservationStatuses',
    defaultMessage: 'Reservation statuses',
  },
  peopleGroup: {
    id: 'settings.nav.peopleGroup',
    defaultMessage: 'People',
  },
  userAccounts: {
    id: 'settings.nav.userAccounts',
    defaultMessage: 'User accounts',
  },
  bookedByNames: {
    id: 'settings.nav.bookedByNames',
    defaultMessage: 'Booked by names',
  },
  serverNames: {
    id: 'settings.nav.serverNames',
    defaultMessage: 'Server names',
  },
  guestFacingSettingsGroup: {
    id: 'settings.nav.guestFacingSettingsGroup',
    defaultMessage: 'Guest-Facing Language',
  },
  widgets: {
    id: 'settings.nav.widgets',
    defaultMessage: 'Widgets',
  },
  widgetsReservation: {
    id: 'settings.nav.widgetsReservation',
    defaultMessage: 'Reservation Widget Language Settings',
  },
  widgetsReservationSettings: {
    id: 'settings.nav.widgetsReservationSettings',
    defaultMessage: 'Reservation Widget Settings',
  },
  widgetsReservationOpen: {
    id: 'settings.nav.widgetsReservationOpen',
    defaultMessage: 'Open Reservation Widget',
  },
  widgetsWaitlist: {
    id: 'settings.nav.widgetsWaitlist',
    defaultMessage: 'Waitlist Widget Language Settings',
  },
  widgetsWaitlistSettings: {
    id: 'settings.nav.widgetsWaitlistSettings',
    defaultMessage: 'Waitlist Widget Settings',
  },
  widgetsWaitlistOpen: {
    id: 'settings.nav.widgetsWaitlistOpen',
    defaultMessage: 'Open Waitlist Widget',
  },
  widgetsEventSettings: {
    id: 'settings.nav.widgetsEventsSettings',
    defaultMessage: 'Event Widget Settings',
  },
  widgetsSubscriptionSettings: {
    id: 'settings.nav.widgetsSubscriptionSettings',
    defaultMessage: 'Subscription Widget Settings',
  },
  widgetsEventOpen: {
    id: 'settings.nav.widgetsEventsOpen',
    defaultMessage: 'Open Event Widget',
  },
  widgetsOrderingSites: {
    id: 'settings.nav.widgetsOrderingSites',
    defaultMessage: 'Ordering Sites',
  },
  pages: {
    id: 'settings.nav.pages',
    defaultMessage: 'Pages',
  },
  pagesFeedback: {
    id: 'settings.nav.pagesFeedback',
    defaultMessage: 'Feedback Page',
  },
  pagesReceipt: {
    id: 'settings.nav.pagesReceipt',
    defaultMessage: 'Receipt Page',
  },
  pagesPaylink: {
    id: 'settings.nav.pagesPaylink',
    defaultMessage: 'Paylink Page',
  },
  pagesCancellation: {
    id: 'settings.nav.pagesCancellation',
    defaultMessage: 'Cancellation Page',
  },
  pagesConfirmation: {
    id: 'settings.nav.pagesConfirmation',
    defaultMessage: 'Confirmation Page',
  },
  pagesEmailPreferenceCenter: {
    id: 'settings.nav.pagesEmailPreferenceCenter',
    defaultMessage: 'Email Preference Center',
  },
  pagesMarketingSubscription: {
    id: 'settings.nav.pagesMarketingSubscription',
    defaultMessage: 'Marketing Subscription',
  },
  emails: {
    id: 'settings.nav.emails',
    defaultMessage: 'Emails',
  },
  emailsReservationConfirmation: {
    id: 'settings.nav.emailsReservationConfirmation',
    defaultMessage: 'Reservation Confirmation',
  },
  emailsReservationUpdate: {
    id: 'settings.nav.emailsReservationUpdate',
    defaultMessage: 'Reservation Update',
  },
  emailsReservationCancellation: {
    id: 'settings.nav.emailsReservationCancellation',
    defaultMessage: 'Reservation Cancellation',
  },
  emailsFeedbackRequestLanguage: {
    id: 'settings.nav.emailsFeedbackRequestLanguage',
    defaultMessage: 'Visit Feedback Request',
  },
  emailsOrderFeedbackRequestLanguage: {
    id: 'settings.nav.emailsOrderFeedbackRequestLanguage',
    defaultMessage: 'Order Feedback Request',
  },
  emailsPaylinkRequestLanguage: {
    id: 'settings.nav.emailsPaylinkRequestLanguage',
    defaultMessage: 'Paylink Request',
  },
  emailsReservationReminder: {
    id: 'settings.nav.emailsReservationReminder',
    defaultMessage: 'Reservation Reminder',
  },
  emailsReservationRequests: {
    id: 'settings.nav.emailsReservationRequests',
    defaultMessage: 'Request Email',
  },
  reservationConfirmation: {
    id: 'settings.nav.emailsReservationsConfirmation',
    defaultMessage: 'Reservation Confirmation',
  },
  emailsCompletedTransactionLanguage: {
    id: 'settings.nav.emailsCompletedTransactionLanguage',
    defaultMessage: 'Completed Transaction',
  },
  emailsGuestlistConfirmation: {
    id: 'settings.nav.emailsGuestlistConfirmation',
    defaultMessage: 'Guestlist Confirmation',
  },
  emailsMarketingSubscriptionConfirmation: {
    id: 'settings.nav.emailsMarketingSubscriptionConfirmation',
    defaultMessage: 'Marketing Subscription Confirmation',
  },
  emailsContinuedMarketingSubscriptionConfirmationEmail: {
    id: 'settings.nav.emailsContinuedMarketingSubscriptionConfirmationEmail',
    defaultMessage: 'Continued Marketing Subscription Confirmation Email',
  },
  emailsMultipleEmails: {
    id: 'settings.nav.emailsMultipleEmails',
    defaultMessage: 'Multiple Emails',
  },
  emailSubscriptionOptIn: {
    id: 'settings.nav.emailSubscriptionOptIn',
    defaultMessage: 'Subscription Opt In',
  },
  emailsEventConfirmation: {
    id: 'settings.nav.emailsEventConfirmation',
    defaultMessage: 'Event Confirmation',
  },
  emailsPriorityAlerts: {
    id: 'settings.nav.emailsPriorityAlerts',
    defaultMessage: 'Priority Alerts',
  },
  SMS: {
    id: 'settings.nav.SMS',
    defaultMessage: 'SMS',
  },
  SMSordering: {
    id: 'settings.nav.SMSordering',
    defaultMessage: 'Ordering',
  },
  SMSres: {
    id: 'settings.nav.SMSres',
    defaultMessage: 'Reservations',
  },
  SMSwaitlist: {
    id: 'settings.nav.SMSwaitlist',
    defaultMessage: 'Waitlist',
  },
  SMSorderMenu: {
    id: 'settings.nav.SMSorderMenu',
    defaultMessage: 'Seated Order Site Link',
  },
  SMScustom: {
    id: 'settings.nav.SMScustom',
    defaultMessage: 'Custom Messages',
  },
  SMSpriorityAlerts: {
    id: 'settings.nav.SMSpriorityAlerts',
    defaultMessage: 'Priority Alerts',
  },
  SMSprivateLine: {
    id: 'settings.nav.SMSprivateLine',
    defaultMessage: 'Private Line',
  },
  ordering: {
    id: 'settings.nav.ordering',
    defaultMessage: 'Ordering',
  },
  policies: {
    id: 'settings.nav.policies',
    defaultMessage: 'Policies',
  },
  policiesPayment: {
    id: 'settings.nav.policiesPayment',
    defaultMessage: 'Cancellation Policies',
  },
  policiesBooking: {
    id: 'settings.nav.policiesBooking',
    defaultMessage: 'Booking Policies',
  },
  policiesOther: {
    id: 'settings.nav.policiesOther',
    defaultMessage: 'Other Policies',
  },
  languageSettings: {
    id: 'settings.nav.languageSettings',
    defaultMessage: 'Language Settings',
  },
  reservationClientImportGroup: {
    id: 'settings.nav.reservationClientImportGroup',
    defaultMessage: 'Reservation & Client Import (Beta)',
  },
  reservationImport: {
    id: 'settings.nav.reservationImport',
    defaultMessage: 'Reservation import',
  },
  clientImport: {
    id: 'settings.nav.clientImport',
    defaultMessage: 'Client import',
  },
  policyInfoText: {
    id: 'settings.policyInfoText',
    defaultMessage:
      'All policies will be shown in policy dropdown in Access Rules setting slideout. You can use html to enhance the appearance of the policy. in policy dropdown in Access Rules setting slideout. You can use html to enhance the appearance of the policy.',
  },
  reservations: {
    id: 'settings.nav.reservations',
    defaultMessage: 'Reservations',
  },
  perks2: {
    id: 'settings.nav.perks2',
    defaultMessage: 'Perks',
  },
} as const)
