import { useMemo } from 'react'
import type { VenueProfile } from '@sevenrooms/core/domain'
import { type Field, type ZodSchema, z } from '@sevenrooms/core/form'

export type ReviewSitesFormData = ZodSchema<ReturnType<typeof useReviewSitesForm>>
export type ReviewSitesFormFields = Field<ReviewSitesFormData>
export type ReviewSiteFieldKey = 'reviewSiteTripadvisor' | 'reviewSiteYelp'

const urlFieldOptional = z.string().url().nullable().or(z.literal(''))

export const useReviewSitesForm = () =>
  useMemo(
    () =>
      z.object({
        reviewSiteTripadvisor: urlFieldOptional,
        reviewSiteYelp: urlFieldOptional,
      }),
    []
  )

export const getReviewSitesFormDefaultValues = (source: VenueProfile): ReviewSitesFormData => ({
  reviewSiteTripadvisor: source.reviewSiteTripadvisor,
  reviewSiteYelp: source.reviewSiteYelp,
})
