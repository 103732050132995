import { useLocales } from '@sevenrooms/core/locales'
import { Label, FormInput } from '@sevenrooms/core/ui-kit/form'
import { Grid } from '@sevenrooms/core/ui-kit/layout'
import { venueProfileMessages } from '../../locales'
import type { ReviewSiteFieldKey, ReviewSitesFormFields } from './ReviewSitesForm.zod'

export interface ReviewSitesFormProps {
  fields: ReviewSitesFormFields
}

const reviewSitesConfig = [
  { fieldKey: 'reviewSiteTripadvisor', labelMessage: venueProfileMessages.tripAdvisorReviewLabel },
  { fieldKey: 'reviewSiteYelp', labelMessage: venueProfileMessages.yelpReviewLabel },
]

export function ReviewSitesForm({ fields }: ReviewSitesFormProps) {
  const { formatMessage } = useLocales()
  const placeholder = formatMessage(venueProfileMessages.protocolPlaceholder)

  return (
    <Grid gridTemplateColumns="1fr 1fr" columnGap="lm" rowGap="m">
      {reviewSitesConfig.map(({ fieldKey, labelMessage }) => (
        <Label key={fieldKey} primary={formatMessage(labelMessage)}>
          <FormInput field={fields.prop(fieldKey as ReviewSiteFieldKey)} placeholder={placeholder} />
        </Label>
      ))}
    </Grid>
  )
}
