import { useCallback, useMemo } from 'react'
import {
  useUpdateExperienceMutation,
  useUpdateExperienceSortingMutation,
  useGetAccessRulesListQuery,
  useGetEventsQuery,
  type UpdateExperienceSortingRequest,
} from '@sevenrooms/core/api'
import {
  type Experience as ExperienceData,
  type ExperiencesListData,
  type AccessRule,
  OfferTypeEnum,
  type Event,
} from '@sevenrooms/core/domain'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { DateTime } from '@sevenrooms/core/timepiece'
import { VerticalDragAndDrop } from '@sevenrooms/core/ui-kit/core'
import { Menu, MenuItem } from '@sevenrooms/core/ui-kit/form'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import { Box, Flex, HStack, Image, Loader, notify, Tooltip, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Header, Link, StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { ExperiencesLocales } from './Experiences.locales'
import Empty from './images/Empty.svg'
import ImagePlaceholder from './images/ImagePlaceholder.png'

interface ExperiencesListProps {
  experiencesList: ExperiencesListData
  openExperienceLinkModal: (id: string) => void
  isFetching: boolean
}
interface ExperienceProps {
  openExperienceLinkModal: (id: string) => void
  experience: ExperienceData
  accessRules?: AccessRule[]
  events?: Event[]
  saveExperience: ({ experience, venueId, experienceId }: { experience: ExperienceData; venueId: string; experienceId: string }) => void
}

function Experience({ experience, openExperienceLinkModal, saveExperience, accessRules, events }: ExperienceProps) {
  const { venueId, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const createdOn = new DateTime(new Date(experience.created)).formatNYearSMonthNDaySTime()
  const setIsFeatured = useCallback(
    (experience: ExperienceData, isFeatured: boolean) =>
      saveExperience({ experience: { ...experience, isFeatured }, venueId, experienceId: experience.id }),
    [saveExperience, venueId]
  )
  const nav = useNavigation()
  const onMarkAsFeatured = useCallback(() => {
    setIsFeatured(experience, true)
  }, [experience, setIsFeatured])

  const onRemoveAsFeatured = useCallback(() => {
    setIsFeatured(experience, false)
  }, [experience, setIsFeatured])

  function HeroImage({ image }: { image: string }) {
    if (image) {
      return (
        <Flex justifyContent="space-around" alignItems="center" borderRadius="s" width="128px">
          <Image
            src={`/.h/download/${image}`}
            alt="Experience image"
            data-test="experience-card-placeholder"
            borderRadius="s"
            height="auto"
            width="128px"
          />
        </Flex>
      )
    }
    return <Image src={ImagePlaceholder} alt="Experience image" width="128px" data-test="offer-card-placeholder" />
  }
  const { formatMessage } = useLocales()
  return (
    <HStack width="100%" height="92px" key={experience.id} justifyContent="space-between" pt="s" pb="s" pl="m" pr="lm">
      <HeroImage image={experience?.heroImage?.photoDict?.small} />

      <VStack width="100%" justifyContent="center" ml="m" mr="s">
        <Text fontSize="m" fontWeight={400} color="secondaryFont" textStyle="body2" width="100%" lineHeight="l">
          {formatMessage(ExperiencesLocales.createdBy, {
            type: experience.offerTypeName,
            userName: experience.createdByUserName,
            createdOn,
          })}
        </Text>
        <Text fontSize="l" fontWeight={700} width="100%" lineHeight="l">
          {experience.name}
        </Text>
        {experience.offerType !== OfferTypeEnum.EVENT &&
          (accessRules?.length ? (
            <Box width="max-content">
              <Tooltip content={<>{accessRules.map(rule => rule.name).join(', ')}</>} displayAction="hover">
                <Text>
                  {accessRules.length === 1
                    ? formatMessage(ExperiencesLocales.attachedOneAccessRule)
                    : formatMessage(ExperiencesLocales.attachedAccessRulesCount, { count: accessRules.length })}
                </Text>
              </Tooltip>
            </Box>
          ) : (
            <HStack alignItems="center" spacing="xs">
              <Icon name="VMSWeb-warning" color="warning" size="lg" />
              <Text>{formatMessage(ExperiencesLocales.noAttachedAccessRulesAttached)}</Text>
            </HStack>
          ))}
        {experience.offerType === OfferTypeEnum.EVENT &&
          (events?.length ? (
            <Box width="max-content">
              <Tooltip content={<>{events.map(event => event.name).join(', ')}</>} displayAction="hover">
                <Text>
                  {events.length === 1
                    ? formatMessage(ExperiencesLocales.attachedOneEvent)
                    : formatMessage(ExperiencesLocales.attachedEventsCount, { count: events.length })}
                </Text>
              </Tooltip>
            </Box>
          ) : (
            <HStack alignItems="center" spacing="xs">
              <Icon name="VMSWeb-warning" color="warning" size="lg" />
              <Text>{formatMessage(ExperiencesLocales.noAttachedEventsAttached)}</Text>
            </HStack>
          ))}
      </VStack>
      {experience.isFeatured && (
        <VStack justifyContent="center">
          <StatusLabel variant="success" width="max-content">
            {formatMessage(ExperiencesLocales.featuredOffer)}
          </StatusLabel>
        </VStack>
      )}

      <VStack width="60px" data-test={`experience-edit-link-${experience.id}`} cursor="pointer" alignSelf="center" alignItems="self-end">
        <Menu alignment="bottomRight">
          <MenuItem
            onClick={() =>
              nav.push(routes.manager2.marketing.offers.editExperience, {
                params: { venueKey, experienceId: experience.id },
              })
            }
          >
            <Text>
              <Icon name="VMSWeb-edit" />
              {formatMessage(commonMessages.edit)}
            </Text>
          </MenuItem>
          {experience.isActive && !experience.isFeatured && (
            <MenuItem onClick={onMarkAsFeatured}>
              <Text>
                <Icon name="VMSWeb-star" />
                {formatMessage(ExperiencesLocales.markAsFeatured)}
              </Text>
            </MenuItem>
          )}
          {experience.isActive && experience.isFeatured && (
            <MenuItem onClick={onRemoveAsFeatured}>
              <Text>
                <Icon name="VMSWeb-cross-star" />
                {formatMessage(ExperiencesLocales.removeAsFeatured)}
              </Text>
            </MenuItem>
          )}
          {venueSettings?.is_sizzle_enabled && (
            <MenuItem onClick={() => openExperienceLinkModal(experience.id)}>
              <Text>
                <Icon name="VMSWeb-link" />
                {formatMessage(ExperiencesLocales.createLink)}
              </Text>
            </MenuItem>
          )}
        </Menu>
      </VStack>
    </HStack>
  )
}

export function ExperiencesList({ experiencesList, openExperienceLinkModal, isFetching }: ExperiencesListProps) {
  const nav = useNavigation()
  const { venueId, venue } = useVenueContext()
  const [updateExperiencesSorting] = useUpdateExperienceSortingMutation()
  const [saveExperience, { isLoading: isSaveExperienceLoading }] = useUpdateExperienceMutation()
  const { data: accessRuleData, isLoading: isAccessRuleLoading } = useGetAccessRulesListQuery({
    venueId,
  })
  const { data: eventsData, isLoading: isEventsLoading } = useGetEventsQuery({ venueName: venue.urlKey })
  const isLoading = useMemo(
    () => isFetching || isSaveExperienceLoading || isAccessRuleLoading || isEventsLoading,
    [isAccessRuleLoading, isFetching, isSaveExperienceLoading, isEventsLoading]
  )
  const accessRules = useMemo<AccessRule[]>(
    () => (accessRuleData ? accessRuleData.filter(rule => rule.experienceID) : []),
    [accessRuleData]
  )
  const events = useMemo<Event[]>(
    () =>
      eventsData
        ? eventsData.filter(
            event => new Date() < new Date(`${event.endDate} ${event.eventEndTime}`) && event.experienceId && event.experienceId !== 'None'
          )
        : [],
    [eventsData]
  )
  const { formatMessage } = useLocales()

  const handleDrag = useCallback(
    async (experiences: ExperienceData[]) => {
      const response = await updateExperiencesSorting({
        sortingMap: experiences.reduce(
          (acc: UpdateExperienceSortingRequest, experience) => ({
            ...acc,
            [experience.id]: experience.sortOrder,
          }),
          {}
        ),
        venueId,
      })
      if ('error' in response) {
        notify({
          content: formatMessage(ExperiencesLocales.experienceAPIError),
          type: 'error',
        })
      }
    },
    [formatMessage, updateExperiencesSorting, venueId]
  )
  return (
    <VStack data-test="experiences-list" spacing="lm" pb="lm">
      {isLoading && (
        <Flex minHeight="274px" justifyContent="center" alignItems="center" data-test="loading-box">
          <Loader>
            <Text textStyle="body1">{formatMessage(ExperiencesLocales.loadingOffers)}</Text>
          </Loader>
        </Flex>
      )}
      {!isLoading && !experiencesList?.draft.length && !experiencesList?.active.length && !experiencesList?.inactive.length && (
        <VStack alignItems="center">
          <Box mb="l">
            <img src={Empty} alt={formatMessage(ExperiencesLocales.noOffersTitle)} />
          </Box>
          <Box>
            <Text fontWeight="bold" fontSize="l">
              {formatMessage(ExperiencesLocales.noOffersTitle)}
            </Text>
          </Box>
          <Box mt="s">
            <Text color="secondaryFont">
              {formatMessage(ExperiencesLocales.emptyOffersNoData)}&nbsp;
              <Link
                to={nav.href(routes.manager2.marketing.offers.createExperience, {
                  params: { venueKey: venue.urlKey },
                })}
              >
                {formatMessage(ExperiencesLocales.emptyOffersLinkNoData)}
              </Link>
            </Text>
          </Box>
        </VStack>
      )}
      {!isLoading && !!experiencesList?.draft.length && (
        <VStack>
          <HStack pb="sm">
            <Header type="h2" lineHeight="xl" width="100%">
              {formatMessage(ExperiencesLocales.draft)}
            </Header>
          </HStack>
          <VStack spacing="m">
            {experiencesList?.draft.map(experience => (
              <HStack key={experience.id} width="100%" borderColor="borders" backgroundColor="primaryBackground" borderRadius="s">
                <Experience
                  experience={experience}
                  openExperienceLinkModal={openExperienceLinkModal}
                  saveExperience={saveExperience}
                  accessRules={accessRules.filter(rule => rule.experienceID === experience.id)}
                  events={events.filter(event => event.experienceId === experience.id)}
                />
              </HStack>
            ))}
          </VStack>
        </VStack>
      )}
      {!isLoading && !!experiencesList?.active.length && (
        <VStack>
          <HStack pb="sm" width="100%">
            <Box>
              <Header type="h2" lineHeight="xl">
                {formatMessage(commonMessages.active)}
              </Header>
              <Text fontSize="m" lineHeight="xl">
                {formatMessage(ExperiencesLocales.activeDescription)}
              </Text>
            </Box>
          </HStack>
          <VerticalDragAndDrop
            containerProps={{
              spacing: 'm',
            }}
            handleDrag={handleDrag}
            items={experiencesList.active}
            rowProps={{
              alignItems: 'center',
              borderColor: 'borders',
              borderRadius: 's',
              pl: 's',
              backgroundColor: 'primaryBackground',
            }}
          >
            {experiencesList?.active.map(experience => (
              <Experience
                key={experience.id}
                experience={experience}
                openExperienceLinkModal={openExperienceLinkModal}
                saveExperience={saveExperience}
                accessRules={accessRules.filter(rule => rule.experienceID === experience.id)}
                events={events.filter(event => event.experienceId === experience.id)}
              />
            ))}
          </VerticalDragAndDrop>
        </VStack>
      )}
      {!isLoading && !!experiencesList?.inactive.length && (
        <VStack>
          <HStack pb="sm" width="100%">
            <Header type="h2" lineHeight="xl" width="100%">
              {formatMessage(commonMessages.inactive)}
            </Header>
          </HStack>
          <VStack spacing="m">
            {experiencesList?.inactive.map(experience => (
              <HStack key={experience.id} width="100%" borderColor="borders" backgroundColor="primaryBackground" borderRadius="s">
                <Experience
                  experience={experience}
                  openExperienceLinkModal={openExperienceLinkModal}
                  saveExperience={saveExperience}
                  accessRules={accessRules.filter(rule => rule.experienceID === experience.id)}
                  events={events.filter(event => event.experienceId === experience.id)}
                />
              </HStack>
            ))}
          </VStack>
        </VStack>
      )}
    </VStack>
  )
}
