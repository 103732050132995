import { useMemo } from 'react'
import snakecaseKeys from 'snakecase-keys'
import { v4 } from 'uuid'
import { FileService, type CreateEditExperienceApi } from '@sevenrooms/core/api'
import {
  type Experience,
  type HeroImage,
  ExperienceVisibilityEnum,
  type OfferType,
  type ExperienceStatus,
  ExperienceStatusEnum,
  OfferTypeEnum,
  type ImageObject,
  type TheForkMenuCourseEnum,
} from '@sevenrooms/core/domain'
import { type Dirtied, z, type ZodSchema } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { formSchema, useImageForm, dateRangePickerMessages } from '@sevenrooms/core/ui-kit/form'
import type { VenueSettings } from '@sevenrooms/mgr-core'
import { formToPDRDetails, type MinimumSpendMatrixFormData, usePDRDetailsForm } from './components/PDR/PDRDetailsForm.zod'
import { formToTheForkDetails, useTheForkDetailsForm } from './components/TheForkDetails/TheForkDetailsFrom.zod'
import { ExperiencesLocales } from './Experiences.locales'

export const useCreateEditExperienceForm = () => {
  const image = useImageForm()
  const theForkDetails = useTheForkDetailsForm()
  const pdrDetails = usePDRDetailsForm()
  const { formatMessage } = useLocales()
  return useMemo(() => {
    const baseSchema = z.object({
      offerType: z.custom<OfferType>(),
      templateId: z.string(),
      offerName: z.string().trim().min(1),
      menu: formSchema.default(null),
      defaultPartySize: z.number().nullable().default(null),
      hidePriceDescription: z.boolean().default(false),
      priceDescription: z.string(),
      descriptionTitle: z.string(),
      descriptionBody: z.string(),
      hideOnDirectory: z.number().default(1),
      status: z.custom<ExperienceStatus>().default(ExperienceStatusEnum.DRAFT),
      heroImage: image.nullable().default(null),
      imageList: z.array(image.nullable()),
      unlinkedAccessRuleIDs: z.array(z.string()).default([]),
      linkedAccessRuleIDs: z.array(z.string()).default([]),
      unlinkedEventIDs: z.array(z.string()).default([]),
      linkedEventIDs: z.array(z.string()).default([]),
      theForkDetails,
      pdrDetails,
    })
    return baseSchema.superRefine((data, ctx) => {
      if (data.status !== ExperienceStatusEnum.DRAFT) {
        if (data.offerType === OfferTypeEnum.PDR) {
          if (!data.pdrDetails?.pdrMaxSeatedCapacity) {
            ctx.addIssue({
              path: ['pdrDetails.pdrMaxSeatedCapacity'],
              message: formatMessage(ExperiencesLocales.fieldValueMinError, { min: 1 }),
              code: z.ZodIssueCode.custom,
            })
          }
        } else {
          if (!data.defaultPartySize) {
            ctx.addIssue({
              path: ['defaultPartySize'],
              message: formatMessage(ExperiencesLocales.fieldValueMinError, { min: 1 }),
              code: z.ZodIssueCode.custom,
            })
          }

          if (!data.hidePriceDescription && data.priceDescription.trim().length === 0) {
            ctx.addIssue({
              path: ['priceDescription'],
              message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
              code: z.ZodIssueCode.custom,
            })
          }
        }
        if (data.offerType !== OfferTypeEnum.PDR && data.descriptionTitle.trim().length === 0) {
          ctx.addIssue({
            path: ['descriptionTitle'],
            message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
            code: z.ZodIssueCode.custom,
          })
        }
        if (data.descriptionBody.trim().length === 0) {
          ctx.addIssue({
            path: ['descriptionBody'],
            message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
            code: z.ZodIssueCode.custom,
          })
        }
        if (data.offerType === OfferTypeEnum.THEFORK) {
          if (!data.theForkDetails) {
            ctx.addIssue({
              path: ['theForkDetails.name'],
              message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
              code: z.ZodIssueCode.custom,
            })
            ctx.addIssue({
              path: ['theForkDetails.price'],
              message: formatMessage(ExperiencesLocales.fieldValueMinError, { min: 1 }),
              code: z.ZodIssueCode.custom,
            })
            ctx.addIssue({
              path: ['theForkDetails.dateRange.startDate'],
              message: formatMessage(dateRangePickerMessages.startDateRequired),
              code: z.ZodIssueCode.custom,
            })
            ctx.addIssue({
              path: ['theForkDetails.dateRange.endDate'],
              message: formatMessage(dateRangePickerMessages.endDateRequired),
              code: z.ZodIssueCode.custom,
            })
          } else {
            if (!data.theForkDetails.name) {
              ctx.addIssue({
                path: ['theForkDetails.name'],
                message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
                code: z.ZodIssueCode.custom,
              })
            }
            if (!data.theForkDetails.price) {
              ctx.addIssue({
                path: ['theForkDetails.price'],
                message: formatMessage(ExperiencesLocales.fieldValueMinError, { min: 1 }),
                code: z.ZodIssueCode.custom,
              })
            }
            if (!data.theForkDetails.dateRange.startDate) {
              ctx.addIssue({
                path: ['theForkDetails.dateRange.startDate'],
                message: formatMessage(dateRangePickerMessages.startDateRequired),
                code: z.ZodIssueCode.custom,
              })
            }
            if (!data.theForkDetails.dateRange.endDate) {
              ctx.addIssue({
                path: ['theForkDetails.dateRange.endDate'],
                message: formatMessage(dateRangePickerMessages.endDateRequired),
                code: z.ZodIssueCode.custom,
              })
            }
            if (
              data.theForkDetails.dateRange.startDate &&
              data.theForkDetails.dateRange.endDate &&
              data.theForkDetails.dateRange.startDate > data.theForkDetails.dateRange.endDate
            ) {
              ctx.addIssue({
                path: ['theForkDetails.dateRange.endDate'],
                message: formatMessage(dateRangePickerMessages.wrongEndDate),
                code: z.ZodIssueCode.custom,
              })
            }
            if (data.theForkDetails.allowEarlyMenuPreview && !data.theForkDetails.previewStartDate) {
              ctx.addIssue({
                path: ['theForkDetails.previewStartDate'],
                message: formatMessage(dateRangePickerMessages.endDateRequired),
                code: z.ZodIssueCode.custom,
              })
            }
            data.theForkDetails.menus.forEach((menu, idx) => {
              menu.items.forEach((item, iidx) => {
                if (!item.name) {
                  ctx.addIssue({
                    path: [`theForkDetails.menus.${idx}.items.${iidx}.name`],
                    message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
                    code: z.ZodIssueCode.custom,
                  })
                }
              })
              if (!menu.dish) {
                ctx.addIssue({
                  path: [`theForkDetails.menus.${idx}.dish`],
                  message: formatMessage(commonMessages.required),
                  code: z.ZodIssueCode.custom,
                })
              } else if (menu.dish === 'CUSTOM_NAME' && !menu.customName) {
                ctx.addIssue({
                  path: [`theForkDetails.menus.${idx}.customName`],
                  message: formatMessage(ExperiencesLocales.fieldLengthError, { minSize: 1 }),
                  code: z.ZodIssueCode.custom,
                })
              }
            })
          }
        }
      } else if (data.defaultPartySize === 0) {
        ctx.addIssue({
          path: ['defaultPartySize'],
          message: formatMessage(ExperiencesLocales.fieldValueMinError, { min: 1 }),
          code: z.ZodIssueCode.custom,
        })
      }
    })
  }, [formatMessage, image, theForkDetails, pdrDetails])
}
export type CreateEditExperienceFormData = ZodSchema<typeof useCreateEditExperienceForm>

export function useDefaultValues(experience?: Experience, venueSettings?: VenueSettings) {
  return useMemo(() => {
    let experienceStatus = ExperienceStatusEnum.DRAFT
    if (experience?.isActive) {
      experienceStatus = ExperienceStatusEnum.ACTIVE
    }
    if (experience?.isInactive) {
      experienceStatus = ExperienceStatusEnum.INACTIVE
    }
    const defaultTheForkData = {
      dateRange: {
        isInfinite: false,
      },
      menus: [
        {
          id: v4(),
          items: [{ name: null, id: v4() }],
        },
      ],
    }

    const defaultOfferType = venueSettings?.is_thefork_integration_enabled ? OfferTypeEnum.THEFORK : OfferTypeEnum.EXPERIENCE
    const offerType = experience?.offerType ?? defaultOfferType
    return {
      offerType,
      templateId: experience?.templateId ?? 'default',
      offerName: experience?.name ?? '',
      defaultPartySize: experience?.defaultPartySize,
      status: experienceStatus,
      hidePriceDescription: experience?.hidePriceDescription,
      priceDescription: experience?.priceDescription ?? '',
      descriptionTitle: experience?.title ?? '',
      descriptionBody: experience?.description ?? '',
      hideOnDirectory: experience?.hideOnDirectory || offerType === OfferTypeEnum.PDR ? 1 : 0,
      heroImage:
        experience?.heroImage && experience.heroImage.rawUrlKey
          ? { rawUrl: `/.h/download/${experience.heroImage.rawUrlKey}`, name: ' ', crop: experience.heroImage.cropInfo }
          : null,
      menu: experience?.menuBlobId ? { rawUrl: experience.menuBlobId, name: experience.menuFilename } : { rawUrl: '', name: '' },
      imageList: experience?.imageList.map(image => ({ rawUrl: `/.h/download/${image.rawUrlKey}`, name: ' ', crop: image.cropInfo })) || [],
      theForkDetails: experience
        ? {
            name: experience.theforkName,
            price: experience.theforkPrice ? parseInt(experience.theforkPrice) : undefined,
            allowEarlyMenuPreview: experience.theforkEarlyPreviewDate !== null,
            bookable: !!experience.theforkActive,
            previewStartDate: experience.theforkEarlyPreviewDate ? new Date(experience.theforkEarlyPreviewDate) : undefined,
            dateRange: {
              startDate: experience.theforkStartDate || undefined,
              endDate: experience.theforkEndDate || undefined,
              isInfinite: false,
            },
            menus:
              experience.theforkMenu.length !== 0
                ? experience.theforkMenu.map(menu => ({
                    id: v4(),
                    customName: menu.customMenuTitle,
                    dish: menu.menuCourse.id as keyof typeof TheForkMenuCourseEnum,
                    items: menu.items.map(item => ({
                      id: v4(),
                      name: item,
                    })),
                  }))
                : defaultTheForkData.menus,
          }
        : defaultTheForkData,
      pdrDetails: experience
        ? {
            pdrMaxSeatedCapacity: experience.pdrMaxSeatedCapacity,
            pdrMinSeatedCapacity: experience.pdrMinSeatedCapacity,
            pdrAmenityList: experience.pdrAmenityList || [],
            pdrRoomType: experience.pdrRoomType,
            pdrTables: experience.pdrTables,
            pdrMaxStandingCapacity: experience.pdrStandingCapacity,
            pdrMinStandingCapacity: experience.pdrStandingMinCapacity,
            pdrSquareFootage: experience.pdrSquareFootage,
            pdrUnitOfMeasurement: experience.pdrUnitOfMeasurement,
            pdrMinSpend: experience.pdrMinSpend,
            pdrMinimumSpendMatrix: experience.pdrMinimumSpendMatrix as MinimumSpendMatrixFormData[],
            pdrAdminFee: experience.pdrAdminFee,
            pdrTaxRateId: experience.pdrTaxRateId,
            pdrTripleSeatRoomId: experience.pdrTripleSeatRoomId,
            pdrFees: experience.pdrFees ?? [],
            pdrDepositFee: experience.pdrDepositFee,
          }
        : {
            pdrStandingCapacity: null,
            pdrMaxSeatedCapacity: null,
            pdrMinSeatedCapacity: null,
            pdrAmenityList: [],
            pdrRoomType: null,
            pdrTables: null,
            pdrSquareFootage: null,
            pdrUnitOfMeasurement: null,
            pdrMinSpend: null,
            pdrMinimumSpendMatrix: [],
            pdrAdminFee: null,
            pdrTaxRateId: null,
            pdrTripleSeatRoomId: null,
            pdrFees: [],
            pdrDepositFee: null,
          },
    }
  }, [experience, venueSettings])
}

export async function formToExperience({
  createEditExperienceFormData,
  experience,
  dirtyFields,
  onError,
}: {
  createEditExperienceFormData: CreateEditExperienceFormData
  experience?: Experience
  dirtyFields: Dirtied<CreateEditExperienceFormData>
  onError: () => void
}): Promise<CreateEditExperienceApi> {
  const heroImageUrlKey = async () => {
    try {
      if (dirtyFields.heroImage && createEditExperienceFormData.heroImage?.fileData) {
        const { urlKey: heroImageUrlKey } = await FileService.uploadFile({
          file: createEditExperienceFormData.heroImage.fileData,
          rurl: Math.random().toString(),
        })
        return heroImageUrlKey
      }
      return undefined
    } catch {
      onError()
      return undefined
    }
  }

  const menuUrl = async () => {
    try {
      if (dirtyFields.menu && createEditExperienceFormData.menu?.fileData) {
        const { urlKey: menuUrl } = await FileService.uploadFile({
          file: createEditExperienceFormData.menu.fileData,
          rurl: (Math.random() * 100 + 1).toString(),
        })
        return menuUrl
      }
      return dirtyFields.menu ? null : undefined
    } catch {
      onError()
      return undefined
    }
  }

  const imageNames = await Promise.all(
    createEditExperienceFormData.imageList.map(async (image, index) => {
      if (!dirtyFields.imageList?.[index]) {
        return image?.rawUrl.replace('/.h/download/', '') || undefined
      }
      try {
        if (image?.fileData) {
          const { urlKey: imageUrlKey } = await FileService.uploadFile({
            file: image.fileData,
            rurl: (Math.random() * 100 + 1).toString(),
          })
          return imageUrlKey ?? undefined
        }
      } catch {
        onError()
      }
      return image?.rawUrl.replace('/.h/download/', '') ?? undefined
    })
  )
  const theForkDetails =
    createEditExperienceFormData.offerType !== OfferTypeEnum.THEFORK || !createEditExperienceFormData.theForkDetails
      ? {}
      : formToTheForkDetails(createEditExperienceFormData.theForkDetails)

  const pdrDetails =
    createEditExperienceFormData.offerType !== OfferTypeEnum.PDR || !createEditExperienceFormData.pdrDetails
      ? {}
      : formToPDRDetails(createEditExperienceFormData.pdrDetails)

  return {
    name: createEditExperienceFormData.offerName,
    offer_type: createEditExperienceFormData.offerType,
    description: createEditExperienceFormData.descriptionBody || '',
    hide_price_description: createEditExperienceFormData.hidePriceDescription,
    price_description: (!createEditExperienceFormData.hidePriceDescription && createEditExperienceFormData.priceDescription) || '',
    template_id: createEditExperienceFormData.templateId,
    status: createEditExperienceFormData.status,
    title: createEditExperienceFormData.descriptionTitle || undefined,
    hide_on_directory: createEditExperienceFormData.hideOnDirectory === 1,
    menu_blob_id: dirtyFields.menu ? await menuUrl() : undefined,
    menu_filename: dirtyFields.menu && createEditExperienceFormData.menu ? createEditExperienceFormData.menu?.name : undefined,
    default_party_size: createEditExperienceFormData.defaultPartySize || undefined,
    hero_image: createEditExperienceFormData.heroImage
      ? getImage(await heroImageUrlKey(), createEditExperienceFormData.heroImage, experience?.heroImage)
      : {},
    image_list: createEditExperienceFormData.imageList
      .map((image, index) => {
        const oldValue = experience?.imageList.filter(image => image.rawUrlKey.replace('/.h/download/', '') === imageNames[index])
        return getImage(imageNames[index], image, oldValue?.length === 1 ? oldValue[0] : experience?.imageList?.[index])
      })
      .filter(image => image != null),
    visibility: ExperienceVisibilityEnum.PUBLIC,
    unlinked_access_rule_ids: createEditExperienceFormData.unlinkedAccessRuleIDs,
    linked_access_rule_ids: createEditExperienceFormData.linkedAccessRuleIDs,
    unlinked_event_ids: createEditExperienceFormData.unlinkedEventIDs,
    linked_event_ids: createEditExperienceFormData.linkedEventIDs,
    ...theForkDetails,
    ...pdrDetails,
  }
}

function getImage(url?: string, formImage?: ImageObject | null, oldValue?: HeroImage) {
  if (url && formImage?.fileData) {
    // new image
    return {
      crop_info: formImage.crop,
      name: formImage.fileData.name,
      raw_url_key: url,
    }
  }
  if (formImage?.crop) {
    // crop existing image
    return {
      raw_url_key: oldValue?.rawUrlKey.replace('/.h/download/', ''),
      crop_info: formImage.crop,
      photo_dict: snakecaseKeys(oldValue?.photoDict || {}),
    }
  }
  return null
}
