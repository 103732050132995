import { useMemo } from 'react'
import { useGetVenueTaxQuery, useGetVenueFeeQuery } from '@sevenrooms/core/api'
import type { Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { FormNumberInput, FormSelect, Label, FormMultiSelect } from '@sevenrooms/core/ui-kit/form'
import { Loader, BaseSection, VStack, HStack, DividerLine } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { MinimumSpendMatrixComponent } from '@sevenrooms/mgr-offers/experiences/components/PDR/MinSpendMatrix'
import type { MinimumSpendMatrixFormData } from '@sevenrooms/mgr-offers/experiences/components/PDR/PDRDetailsForm.zod'
import { spacesMessages } from '../../spaces.locales'
import type { SpaceFormType } from './Space.zod'

interface FeesProps {
  field: Field<SpaceFormType>
}

export function Fees({ field }: FeesProps) {
  const { formatMessage } = useLocales()
  const { venueId } = useVenueContext()

  const { data: taxes, isFetching: isFetchingTaxes } = useGetVenueTaxQuery({ venueId })
  const { data: feesData, isFetching: isFetchingFees } = useGetVenueFeeQuery({ venueId })

  const taxesOptions = useMemo(() => taxes?.map(tax => ({ id: tax.id, label: `${tax.name} [${tax.value}%]` })) ?? [], [taxes])
  const feesOptions = useMemo(
    () => feesData?.fees.filter(fee => fee.id != null).map(fee => ({ id: fee.id, label: fee.name })) ?? [],
    [feesData?.fees]
  ) as { id: string; label: string }[]

  const isLoading = isFetchingTaxes || isFetchingFees
  return isLoading ? (
    <Loader />
  ) : (
    <BaseSection title={formatMessage(spacesMessages.fees)} description={formatMessage(spacesMessages.feesDescription)}>
      <VStack spacing="lm" p="lm">
        <VStack spacing="m">
          <Label
            primary={<Text fontWeight="bold">{formatMessage(spacesMessages.minimumSpendRangeTitle)}</Text>}
            secondary={formatMessage(spacesMessages.minimumSpendRangeDescription)}
          />
          <HStack width="50%" spacing="lm">
            <Label primary={formatMessage(spacesMessages.minimumSpendRangeMinimum)} width="100%">
              <FormNumberInput field={field.prop('pdrMinSpendMin')} greaterThanZero />
            </Label>
            <Label primary={formatMessage(spacesMessages.minimumSpendRangeMaximum)} width="100%">
              <FormNumberInput field={field.prop('pdrMinSpendMax')} greaterThanZero />
            </Label>
          </HStack>
        </VStack>

        <DividerLine color="borders" ml="none" mr="none" />

        <VStack spacing="m">
          <Text fontWeight="bold">{formatMessage(spacesMessages.dwMinSpendTable)}</Text>
          <Label
            primary={formatMessage(spacesMessages.pdrDefaultMinSpend)}
            secondary={formatMessage(spacesMessages.pdrDefaultMinSpendHelper)}
            width="50%"
          >
            <FormNumberInput field={field.prop('pdrMinSpend')} greaterThanZero />
          </Label>
          <MinimumSpendMatrixComponent field={field.prop('pdrMinimumSpendMatrix') as Field<MinimumSpendMatrixFormData[]>} />
        </VStack>

        <DividerLine color="borders" ml="none" mr="none" />

        <VStack spacing="m">
          <Label
            primary={<Text fontWeight="bold">{formatMessage(spacesMessages.allOtherFees)}</Text>}
            secondary={formatMessage(spacesMessages.allOtherFeesDescription)}
            width="50%"
          >
            <FormMultiSelect field={field.prop('pdrFees')} options={feesOptions} />
          </Label>
          <HStack spacing="lm" width="50%">
            <Label primary={formatMessage(spacesMessages.pdrAdminFee)}>
              <FormNumberInput max={100} field={field.prop('pdrAdminFee')} />
            </Label>
            <Label primary={formatMessage(spacesMessages.pdrTaxRateId)}>
              <FormSelect field={field.prop('pdrTaxRateId')} options={taxesOptions} withEmpty />
            </Label>
          </HStack>
        </VStack>
      </VStack>
    </BaseSection>
  )
}
