import { Box } from '@sevenrooms/core/ui-kit/layout'
import { MarketingStepperFlow } from '@sevenrooms/mgr-marketing-templates-gallery/components/MarketingStepperFlow'

export function CreateExperienceFromTemplate() {
  return (
    <Box pt="m" pb="m" borderWidth="s" borderBottomColor="borders" backgroundColor="primaryBackground">
      <MarketingStepperFlow />
    </Box>
  )
}
