import { useMemo, useState, useCallback } from 'react'
import type { Experience, AccessRule, AccessRuleToShow } from '@sevenrooms/core/domain'
import { type Field, useController } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, Spreadsheet, Window, type DataTableColumn, HStack } from '@sevenrooms/core/ui-kit/layout'
import { Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { AccessRulesSlideout } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideout'
import type { AccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { ExperiencesLocales } from '../../Experiences.locales'
import { AccessRuleSelectorLocales } from './AccessRuleSelector.locales'
import { AccessRuleSelectorModal } from './AccessRuleSelectorModal'
import { UnlinkAccessRuleModal } from './UnlinkAccessRuleModal'

interface ExperienceAccessRulesProps {
  experience?: Experience
  accessRuleData?: AccessRule[]
  accessRulesSlideoutData?: AccessRulesSlideoutData
  unlinkedAccessRuleIDsField: Field<string[]>
  linkedAccessRuleIDsField: Field<string[]>
}
export function ExperienceAccessRules({
  experience,
  accessRuleData,
  accessRulesSlideoutData,
  unlinkedAccessRuleIDsField,
  linkedAccessRuleIDsField,
}: ExperienceAccessRulesProps) {
  const unlinkedAccessRuleIDsController = useController(unlinkedAccessRuleIDsField)
  const linkedAccessRuleIDsController = useController(linkedAccessRuleIDsField)
  const today = useMemo(() => new Date(), [])
  const [isLinkToAccessRuleModalOpen, setLinkToAccessRuleModalOpen] = useState(false)
  const [createNewAccessRule, setCreateNewAccessRule] = useState(false)
  const [accessRuleToUnlink, setAccessRuleToUnlink] = useState<null | AccessRuleToShow>(null)
  const [accessRuleToShow, setAccessRuleToShow] = useState<undefined | AccessRule>(undefined)
  const { formatMessage } = useLocales()

  const columns = useMemo<DataTableColumn<AccessRuleToShow>[]>(
    () => [
      {
        header: formatMessage(ExperiencesLocales.accessRuleName),
        render: (value: AccessRuleToShow) => <Text>{value.name || '-'}</Text>,
        key: 'name',
      },
      {
        header: formatMessage(ExperiencesLocales.accessRuleSchedule),
        render: (value: AccessRuleToShow) => <Text>{`${value.dateRange} on ${value.days} at ${value.time}`}</Text>,
        key: 'startDate',
      },
      {
        header: formatMessage(AccessRuleSelectorLocales.accessRuleSelectTime),
        render: (value: AccessRuleToShow) => <Text>{value.time}</Text>,
        key: 'time',
      },
      {
        header: formatMessage(ExperiencesLocales.accessRulePartySize),
        render: (value: AccessRuleToShow) => <Text>{value.partySize}</Text>,
        key: 'partySize',
      },
      {
        header: '',
        render: (value: AccessRuleToShow, index: number) => (
          <Box textAlign="right">
            <Button
              variant="tertiary"
              icon="VMSWeb-eye"
              onClick={() => setAccessRuleToShow(accessRuleData?.find(ar => ar.id === value.id))}
              data-test={`open-ar-${index}`}
            />
          </Box>
        ),
        key: 'ar',
      },
      {
        header: '',
        render: (value: AccessRuleToShow, index: number) => (
          <Button variant="tertiary" icon="VMSWeb-unlink" onClick={() => setAccessRuleToUnlink(value)} data-test={`unlink-${index}`} />
        ),
        key: 'unlink',
      },
    ],
    [accessRuleData, formatMessage]
  )

  const accessRules = useMemo<AccessRuleToShow[]>(
    () =>
      accessRuleData
        ? accessRuleData
            .filter(rule => !rule.experienceID || (experience && rule.experienceID === experience?.id))
            .map(rule => {
              const days = rule.date ? rule.date.formatSWeek() : rule.dayOfWeekName?.join(', ') || ''

              const dateRange = rule.date
                ? `${rule.date.formatNYearNMonthNDay()} - ${rule.date.formatNYearNMonthNDay()}`
                : `${rule.startDate?.formatNYearNMonthNDay()} - ${rule.endDate?.formatNYearNMonthNDay() || 'Indefinite'}`

              let time = rule.startEndTimesByShiftDisplay.flatMap(i => i.times.join(' - ')).join(', ')
              if (rule.specificTimesDisplay?.length) {
                time = rule.specificTimesDisplay?.join(', ')
              } else if (rule.startTimeDisplay) {
                time = `${rule.startTimeDisplay} - ${rule.endTimeDisplay}`
              }

              const partySize = rule.partySizeMin
                ? `${rule.partySizeMin}-${rule.partySizeMax}`
                : formatMessage(AccessRuleSelectorLocales.followShrift)

              return {
                ...rule,
                days,
                dateRange,
                time,
                partySize,
              }
            })
        : [],
    [accessRuleData, experience, formatMessage]
  )
  const selectedAccessRules = useMemo(
    () =>
      accessRules.filter(
        accessRule =>
          (accessRule.experienceID === experience?.id && !unlinkedAccessRuleIDsController.field.value?.includes(accessRule.id)) ||
          linkedAccessRuleIDsController.field.value?.includes(accessRule.id)
      ),
    [accessRules, experience?.id, linkedAccessRuleIDsController.field.value, unlinkedAccessRuleIDsController.field.value]
  )
  const unlinkAccessRule = useCallback(() => {
    if (accessRuleToUnlink) {
      if (linkedAccessRuleIDsController.field.value && linkedAccessRuleIDsController.field.value.includes(accessRuleToUnlink.id)) {
        linkedAccessRuleIDsController.field.onChange(
          linkedAccessRuleIDsController.field.value.filter(ruleId => ruleId !== accessRuleToUnlink.id)
        )
      } else {
        unlinkedAccessRuleIDsController.field.onChange(
          unlinkedAccessRuleIDsController.field.value
            ? [...unlinkedAccessRuleIDsController.field.value, accessRuleToUnlink.id]
            : [accessRuleToUnlink.id]
        )
      }
    }
    setAccessRuleToUnlink(null)
  }, [accessRuleToUnlink, linkedAccessRuleIDsController.field, unlinkedAccessRuleIDsController.field])
  return (
    <>
      <Box>
        <BaseSection
          title={formatMessage(ExperiencesLocales.associatedAccessRules)}
          description={
            experience ? (
              formatMessage(ExperiencesLocales.linkOfferText)
            ) : (
              <Status kind="warning">{formatMessage(ExperiencesLocales.accessRuleWarning)}</Status>
            )
          }
        >
          <Box p="lm">
            {unlinkedAccessRuleIDsController.field.value || linkedAccessRuleIDsController.field.value ? (
              <Box backgroundColor="warningBackground" borderRadius="s" pt="s" pb="s" pl="m">
                <Text fontStyle="italic">{formatMessage(ExperiencesLocales.saveChangesToComplete)}</Text>
              </Box>
            ) : (
              selectedAccessRules.length === 0 && (
                <Box backgroundColor="warningBackground" borderRadius="s" pt="s" pb="s" pl="m">
                  <Text fontStyle="italic">{formatMessage(ExperiencesLocales.rememberToLinkAccessRule)}</Text>
                </Box>
              )
            )}
            <Spreadsheet data={selectedAccessRules} columns={columns} noPagination noBorder />
            <HStack mt="lm" spacing="s">
              <Button
                data-test="create-new-access-rule-button"
                variant="secondary"
                disabled={!experience}
                onClick={() => setCreateNewAccessRule(true)}
              >
                {formatMessage(ExperiencesLocales.createNewAR)}
              </Button>
              <Button
                data-test="link-access-rule-button"
                icon="VMSWeb-link"
                variant="tertiary"
                noPadding
                disabled={!experience}
                onClick={() => setLinkToAccessRuleModalOpen(true)}
              >
                {formatMessage(ExperiencesLocales.linkAccessRule)}
              </Button>
            </HStack>
          </Box>
        </BaseSection>
      </Box>

      <Window active={isLinkToAccessRuleModalOpen}>
        <AccessRuleSelectorModal
          setSelectedAccessRuleIds={linkedAccessRuleIDsController.field.onChange}
          selectedAccessRuleIds={linkedAccessRuleIDsController.field.value}
          close={() => setLinkToAccessRuleModalOpen(false)}
          accessRules={accessRules.filter(rule => !rule.experienceID || unlinkedAccessRuleIDsController.field.value?.includes(rule.id))}
        />
      </Window>
      <Window active={accessRuleToUnlink !== null}>
        <UnlinkAccessRuleModal
          onDiscard={() => setAccessRuleToUnlink(null)}
          onSubmit={unlinkAccessRule}
          accessRuleName={accessRuleToUnlink?.name || ''}
        />
      </Window>
      {createNewAccessRule && accessRulesSlideoutData && (
        <AccessRulesSlideout
          selectedDay={today.toISOString()}
          onClose={() => setCreateNewAccessRule(false)}
          onRuleSave={() => {
            setCreateNewAccessRule(false)
          }}
          onRuleDelete={() => setCreateNewAccessRule(false)}
          startDate={today}
          mode="new-item" // Forces slideout to open in edit mode
          shiftCategories={[]}
          accessRulesSlideoutData={accessRulesSlideoutData}
          defaultValues={{
            guestFacing: {
              offer: experience?.id ?? null,
            },
          }}
        />
      )}
      {accessRuleToShow?.startDate && accessRulesSlideoutData && (
        <AccessRulesSlideout
          selectedDay={
            accessRuleToShow.startDate.toJsDate() < today ? today.toISOString() : accessRuleToShow.startDate.toJsDate().toISOString()
          }
          onClose={() => setAccessRuleToShow(undefined)}
          onRuleSave={() => {
            setAccessRuleToShow(undefined)
          }}
          onRuleDelete={() => {
            setAccessRuleToShow(undefined)
          }}
          startDate={today}
          shiftCategories={[]}
          accessRulesSlideoutData={accessRulesSlideoutData}
          accessRule={accessRuleToShow}
        />
      )}
    </>
  )
}
