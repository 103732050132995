import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useMemo, useState } from 'react'
import { useCreateExperienceMutation, useEditExperienceMutation, useGetAccessRulesListQuery, useGetEventsQuery } from '@sevenrooms/core/api'
import { type Experience, ExperienceStatusEnum, OfferTypeEnum, type ImageObject, ExperienceTemplateTypeEnum } from '@sevenrooms/core/domain'
import { useFieldArray, useForm, useWatchMany } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import {
  Button,
  FileUploader,
  Form,
  FormInput,
  ImageUploader,
  Label,
  Radio,
  FormToggle,
  FormNumberInput,
} from '@sevenrooms/core/ui-kit/form'
import {
  BaseSection,
  Box,
  DividerLine,
  Grid,
  HStack,
  Loader,
  notify,
  Flex,
  VStack,
  Breadcrumbs,
  Window,
} from '@sevenrooms/core/ui-kit/layout'
import { FormEditor, defaultConfig } from '@sevenrooms/core/ui-kit/optional'
import { Header, StatusLabel, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { SettingsPageContent, SettingsPageMeta, useVenueContext, useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { CancelEditWarningModal } from './CancelEditWarningModal'
import { AttacheEvent } from './components/AttacheEvent/AttacheEvent'
import { ExperienceAccessRules } from './components/ExperienceAccessRules/ExperienceAccessRules'
import { PDR } from './components/PDR/PDR'
import { TheForkDetails } from './components/TheForkDetails/TheForkDetails'
import {
  type CreateEditExperienceFormData,
  formToExperience,
  useCreateEditExperienceForm,
  useDefaultValues,
} from './CreateEditExperienceForm.zod'
import { ExperienceFormEditorWrapper } from './ExperienceFormEditorWrapper'
import { ExperiencesLocales } from './Experiences.locales'
import { ExperiencesActionButtons } from './ExperiencesActionButtons'
import { ExperienceThemeSelector } from './ExperienceThemeSelector'

interface CreateEditExperienceFormProps {
  experience?: Experience
}
export function CreateEditExperienceForm({ experience }: CreateEditExperienceFormProps) {
  const { venue, venueId, venueKey } = useVenueContext()
  const { venueSettings } = useVenueSettingsContext()
  const [stayInPage, setStayInPage] = useState(false)
  const { formatMessage } = useLocales()
  const [createExperience, { isLoading: isCreateExperienceLoading }] = useCreateExperienceMutation()
  const [updateExperience, { isLoading: isEditExperienceLoading }] = useEditExperienceMutation()
  const getPageTitleText = useMemo(
    () => (experience ? experience.name : formatMessage(ExperiencesLocales.createOffer)),
    [experience, formatMessage]
  )

  const [isImageUploading, setImageUploading] = useState(false)
  const [showExitWithoutChangesModal, setShowExitWithoutChangesModal] = useState(false)

  const getPageTitle = useMemo(
    () =>
      experience ? (
        <HStack>
          <Header type="h1" mr="s">
            {experience.name}
          </Header>
          {experience.isDraft ? (
            <StatusLabel variant="status">{formatMessage(ExperiencesLocales.draft)}</StatusLabel>
          ) : (
            <StatusLabel variant={experience.isActive ? 'success' : 'inactive'}>
              {experience.isActive ? formatMessage(commonMessages.active) : formatMessage(commonMessages.inactive)}
            </StatusLabel>
          )}
        </HStack>
      ) : (
        formatMessage(ExperiencesLocales.createOffer, { perkName: 'Perk' })
      ),
    [experience, formatMessage]
  )

  const createEditFormSchema = useCreateEditExperienceForm()
  const defaultValues = useDefaultValues(experience, venueSettings)
  const form = useForm(createEditFormSchema, { defaultValues, mode: 'onSubmit' })
  const {
    field,
    reset,
    trigger,
    formState: { dirtyFields },
    setValue,
  } = form
  const { fields: images, append: appendImage, remove: removeImage } = useFieldArray(field, 'imageList')
  const [offerTypeValue, hidePriceDescriptionValue, templateIdValue, hideOnDirectory] = useWatchMany(field, [
    'offerType',
    'hidePriceDescription',
    'templateId',
    'hideOnDirectory',
  ])

  const nav = useNavigation()
  const navigateToOffersPage = useCallback(() => {
    nav.push(routes.manager2.marketing.offers, { params: { venueKey: venue.urlKey } })
  }, [nav, venue])
  const { data: accessRulesSlideoutData, isLoading: isAccessRolesSlideoutDataLoading } = useAccessRulesSlideoutData()

  const { data: accessRuleData, isLoading: isAccessRuleLoading } = useGetAccessRulesListQuery(
    experience && offerTypeValue !== OfferTypeEnum.EVENT ? { venueId } : skipToken
  )
  const { data: eventsData, isLoading: isEventsLoading } = useGetEventsQuery(
    experience && offerTypeValue === OfferTypeEnum.EVENT ? { venueName: venue.urlKey } : skipToken
  )

  const imageAspectRatios = useMemo(
    () => ({
      hero: templateIdValue === ExperienceTemplateTypeEnum.DEFAULT ? 2.25 : 1.97,
      alt: templateIdValue === ExperienceTemplateTypeEnum.DEFAULT ? 1.5 : 2.25,
    }),
    [templateIdValue]
  )

  const handleOnSubmit = useCallback(
    async (createEditExperienceFormData: CreateEditExperienceFormData) => {
      try {
        if (experience) {
          setImageUploading(true)
          const updateExperienceData = await formToExperience({
            createEditExperienceFormData,
            dirtyFields,
            experience,
            onError: () => {
              notify({
                content: formatMessage(ExperiencesLocales.experienceAPIError),
                type: 'error',
              })
            },
          })
          setImageUploading(false)
          await updateExperience({
            experience: updateExperienceData,
            experienceId: experience.id,
            venueId: venue.id,
          }).unwrap()
          notify({
            content: formatMessage(ExperiencesLocales.experienceUpdated, { name: createEditExperienceFormData.offerName }),
            type: 'success',
          })
          if (!stayInPage) {
            nav.push(routes.manager2.marketing.offers, { params: { venueKey: venue.urlKey } })
          }
        } else {
          setImageUploading(true)
          const createExperienceData = await formToExperience({
            createEditExperienceFormData,
            dirtyFields,
            experience,
            onError: () => {
              notify({
                content: formatMessage(ExperiencesLocales.experienceAPIError),
                type: 'error',
              })
            },
          })
          setImageUploading(false)
          const createdExperience = await createExperience({
            experience: createExperienceData,
            venueId: venue.id,
          }).unwrap()
          notify({
            content: formatMessage(ExperiencesLocales.experienceCreated, { name: createEditExperienceFormData.offerName }),
            type: 'success',
          })
          if (!stayInPage) {
            nav.push(routes.manager2.marketing.offers, { params: { venueKey: venue.urlKey } })
          } else {
            nav.push(routes.manager2.marketing.offers.editExperience, {
              params: { venueKey: venue.urlKey, experienceId: createdExperience.id },
            })
          }
        }
      } catch {
        notify({
          content: formatMessage(ExperiencesLocales.experienceAPIError),
          type: 'error',
        })
      }
      reset({}, { keepValues: true })
    },
    [createExperience, dirtyFields, experience, formatMessage, nav, reset, stayInPage, updateExperience, venue.id, venue.urlKey]
  )

  const handleOnInvalid = useCallback(data => {
    // eslint-disable-next-line no-console
    console.error(data)
  }, [])

  return isCreateExperienceLoading ||
    isEditExperienceLoading ||
    isImageUploading ||
    isAccessRuleLoading ||
    isEventsLoading ||
    isAccessRolesSlideoutDataLoading ? (
    <Loader />
  ) : (
    <Box data-test="create-or-edit-experience-page">
      <SettingsPageMeta venue={venue?.name} title={getPageTitleText} />
      <Form {...form} onSubmit={handleOnSubmit} onInvalid={handleOnInvalid}>
        <SettingsPageContent
          breadcrumbs={
            <Breadcrumbs>
              <Button
                data-test="experiences-title-button"
                href={
                  Object.keys(dirtyFields).length === 0 ? nav.href(routes.manager2.marketing.offers, { params: { venueKey } }) : undefined
                }
                variant="tertiary"
                target="_self"
                noPadding
                onClick={() => {
                  if (Object.keys(dirtyFields).length !== 0) {
                    setShowExitWithoutChangesModal(true)
                  }
                }}
              >
                {formatMessage(ExperiencesLocales.experiencesTitle)}
              </Button>
              <Text>{getPageTitleText}</Text>
            </Breadcrumbs>
          }
          title={getPageTitle}
          actions={
            <ExperiencesActionButtons
              disabled={false}
              onCancel={navigateToOffersPage}
              experienceId={experience?.id}
              isActive={experience?.isActive}
              isInActive={experience?.isInactive}
              setStayInPage={setStayInPage}
              onSaveAndPublish={() => {
                setValue('status', ExperienceStatusEnum.ACTIVE)
              }}
              onDeactivate={() => {
                setValue('status', ExperienceStatusEnum.INACTIVE)
              }}
              onSaveAsDraft={() => {
                setValue('status', ExperienceStatusEnum.DRAFT)
              }}
            />
          }
        >
          <VStack pl="lm" spacing="lm" pb="lm">
            <BaseSection title={formatMessage(ExperiencesLocales.offerOverview)}>
              {(venueSettings?.event_widget_enabled ||
                venueSettings?.is_thefork_integration_enabled ||
                venueSettings?.is_pdr_integration_enabled) && (
                <Box pl="lm" pr="lm" pt="lm" maxWidth="50%">
                  <Label>{formatMessage(ExperiencesLocales.choseType)}</Label>
                  <VStack spacing="s" pt="s">
                    <Radio
                      description={formatMessage(ExperiencesLocales.experienceTypeDescription)}
                      value={OfferTypeEnum.EXPERIENCE}
                      field={field.prop('offerType')}
                      checked={offerTypeValue === OfferTypeEnum.EXPERIENCE}
                    >
                      {formatMessage(ExperiencesLocales.experience)}
                    </Radio>
                    {venueSettings?.event_widget_enabled && (
                      <Radio
                        description={formatMessage(ExperiencesLocales.eventTypeDescription)}
                        value={OfferTypeEnum.EVENT}
                        field={field.prop('offerType')}
                        checked={offerTypeValue === OfferTypeEnum.EVENT}
                      >
                        {formatMessage(ExperiencesLocales.events)}
                      </Radio>
                    )}
                    {venueSettings?.is_thefork_integration_enabled && (
                      <Radio
                        description={formatMessage(ExperiencesLocales.theForkDescription)}
                        value={OfferTypeEnum.THEFORK}
                        field={field.prop('offerType')}
                        checked={offerTypeValue === OfferTypeEnum.THEFORK}
                      >
                        {formatMessage(ExperiencesLocales.theFork)}
                      </Radio>
                    )}
                    {venueSettings?.is_pdr_integration_enabled && (
                      <Radio
                        description={formatMessage(ExperiencesLocales.pdrDescription)}
                        value={OfferTypeEnum.PDR}
                        field={field.prop('offerType')}
                        checked={offerTypeValue === OfferTypeEnum.PDR}
                      >
                        {formatMessage(ExperiencesLocales.pdr)}
                      </Radio>
                    )}
                  </VStack>
                </Box>
              )}
              <HStack justifyContent="space-between" p="lm">
                <Flex flexDirection="column" flex="1">
                  {offerTypeValue === OfferTypeEnum.PDR ? (
                    <Label
                      primary={formatMessage(ExperiencesLocales.privateDiningName)}
                      secondary={formatMessage(ExperiencesLocales.privateDiningNameDescription)}
                    />
                  ) : (
                    <Label
                      primary={formatMessage(ExperiencesLocales.offerName)}
                      secondary={formatMessage(ExperiencesLocales.offerNameDescription)}
                    />
                  )}
                  <FormInput field={field.prop('offerName')} placeholder={formatMessage(ExperiencesLocales.enterName)} fullWidth />
                </Flex>
              </HStack>
            </BaseSection>
            {offerTypeValue === OfferTypeEnum.PDR ? (
              <PDR
                experience={experience}
                accessRuleData={accessRuleData}
                field={field}
                isTripleSeatDinewiseEnabled={venueSettings ? venueSettings.is_triple_seat_dinewise_enabled : false}
                isPDRDepositFeeEnabled={venueSettings ? venueSettings.is_pdr_deposit_fee_enabled : false}
              />
            ) : (
              <>
                {experience && offerTypeValue !== OfferTypeEnum.EVENT && (
                  <ExperienceAccessRules
                    experience={experience}
                    accessRuleData={accessRuleData}
                    accessRulesSlideoutData={accessRulesSlideoutData}
                    unlinkedAccessRuleIDsField={field.prop('unlinkedAccessRuleIDs')}
                    linkedAccessRuleIDsField={field.prop('linkedAccessRuleIDs')}
                  />
                )}
                {experience && offerTypeValue === OfferTypeEnum.EVENT && (
                  <AttacheEvent
                    experience={experience}
                    eventsData={eventsData}
                    unlinkedEventIDsField={field.prop('unlinkedEventIDs')}
                    linkedEventIDsField={field.prop('linkedEventIDs')}
                  />
                )}
                <BaseSection title={formatMessage(ExperiencesLocales.offerDetail)}>
                  <VStack justifyContent="space-between" spacing="lm" p="lm">
                    <HStack spacing="lm">
                      <Flex flexDirection="column" flex="4">
                        <Label primary={formatMessage(ExperiencesLocales.defaultPartySize)} />
                        <FormNumberInput field={field.prop(`defaultPartySize`)} />
                      </Flex>
                      <Flex flexDirection="column" flex="3">
                        <Label primary={formatMessage(ExperiencesLocales.price)} />
                        <FormInput field={field.prop('priceDescription')} disabled={hidePriceDescriptionValue} />
                      </Flex>
                      <Flex flexDirection="column" justifyContent="center" alignItems="center" flex="1">
                        <Label primary={formatMessage(ExperiencesLocales.hidePrice)} />
                        <FormToggle
                          field={field.prop('hidePriceDescription')}
                          onChange={value => {
                            if (value) {
                              setValue('priceDescription', '')
                            }
                            trigger('priceDescription', {})
                          }}
                        />
                      </Flex>
                    </HStack>
                    <HStack justifyContent="space-between">
                      <Flex flexDirection="column" flex="1">
                        <Label primary={formatMessage(ExperiencesLocales.descriptionTitle)} />
                        <FormInput field={field.prop(`descriptionTitle`)} fullWidth />
                      </Flex>
                    </HStack>
                    <HStack justifyContent="space-between">
                      <Flex flexDirection="column" flex="1">
                        <Label primary={formatMessage(ExperiencesLocales.descriptionBody)} />
                        <ExperienceFormEditorWrapper>
                          <FormEditor
                            field={field.prop(`descriptionBody`)}
                            config={{
                              ...defaultConfig,
                              heightMin: 100,
                              toolbarButtons: [
                                'textColor',
                                'bold',
                                'italic',
                                'underline',
                                'alignLeft',
                                'alignCenter',
                                'alignRight',
                                'formatUL',
                                'formatOL',
                                'insertLink',
                              ],
                              toolbarBottom: false,
                              useClasses: false,
                            }}
                            data-test="experiences-description-body"
                            isLoyaltyAndPerksEnabled={venueSettings?.is_loyalty_and_perks_enabled}
                            referralProgramEnabled={venueSettings?.referral_program_enabled}
                          />
                        </ExperienceFormEditorWrapper>
                      </Flex>
                    </HStack>
                    <HStack justifyContent="space-between">
                      <Flex flexDirection="column" flex="1">
                        <Label
                          primary={formatMessage(ExperiencesLocales.menu)}
                          secondary={formatMessage(ExperiencesLocales.menuSupportedFiles)}
                        />
                        <FileUploader field={field.prop('menu')} hideDropZone />
                      </Flex>
                    </HStack>
                    <Box>
                      <DividerLine />
                    </Box>
                    <Box>
                      <Label primary={formatMessage(ExperiencesLocales.displayOffer)} />
                      <VStack spacing="xs">
                        {/* this is backwards for historical reasons */}
                        <Radio value={0} checked={hideOnDirectory === 0} field={field.prop('hideOnDirectory')}>
                          {formatMessage(commonMessages.yes)}
                        </Radio>
                        <Radio value={1} checked={hideOnDirectory === 1} field={field.prop('hideOnDirectory')}>
                          {formatMessage(commonMessages.no)}
                        </Radio>
                      </VStack>
                    </Box>
                    <Box>
                      <Label primary={formatMessage(ExperiencesLocales.themeLabel)} />
                      <ExperienceThemeSelector field={field.prop('templateId')} />
                    </Box>
                  </VStack>
                </BaseSection>
                {offerTypeValue === OfferTypeEnum.THEFORK && <TheForkDetails field={field.prop('theForkDetails')} />}
                <BaseSection title={formatMessage(ExperiencesLocales.images)}>
                  <VStack spacing="lm" p="lm">
                    <Label
                      primary={formatMessage(ExperiencesLocales.headerImage)}
                      secondary={formatMessage(ExperiencesLocales.headerImageSize)}
                    />
                    <ImageUploader field={field.prop('heroImage')} aspect={imageAspectRatios.hero} unit="%" />
                    <DividerLine />
                    <Label
                      primary={formatMessage(ExperiencesLocales.addAlternativeImages)}
                      secondary={formatMessage(ExperiencesLocales.headerImageSize)}
                    />
                    {images.length > 0 && (
                      <Grid
                        gridTemplateColumns="repeat(auto-fill, minmax(210px, 1fr))"
                        gap="lm"
                        gridAutoRows="min-content"
                        alignItems="center"
                      >
                        {images.map((image, idx) => (
                          <ImageUploader
                            editText=""
                            deleteText=""
                            uploadText=""
                            variant="small"
                            onChange={(f?: ImageObject) => !f && removeImage(idx)}
                            field={field.prop(`imageList.${idx}`)}
                            key={image.id}
                            alwaysShowDeleteButton
                            aspect={imageAspectRatios.alt}
                            unit="%"
                          />
                        ))}
                      </Grid>
                    )}
                    <Box>
                      <Button
                        data-test="experiences-add-image-button"
                        variant="secondary"
                        icon="VMSWeb-add"
                        onClick={() => appendImage(null)}
                        disabled={images.length === 8}
                      >
                        {formatMessage(ExperiencesLocales.addImage)}
                      </Button>
                    </Box>
                  </VStack>
                </BaseSection>
              </>
            )}
          </VStack>
        </SettingsPageContent>
      </Form>
      <Window active={showExitWithoutChangesModal}>
        <CancelEditWarningModal closeExitWithoutChangesModal={() => setShowExitWithoutChangesModal(false)} />
      </Window>
    </Box>
  )
}
